import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../constants/constants";
import { getValueFromStorage } from "../helpers/helpers";

export const CategoryApi = createApi({
  reducerPath: "category",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      headers.set("Content-type", "application/json");
      headers.set("authorization", `Bearer ${getValueFromStorage("token")}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getCategory: builder.query({
      query: ({ currentPage = 1, resultPerPage = 10000 }) => ({
        url: `/api/category`,
        method: "GET",
        params: {
          current_page: currentPage,
          result_per_page: resultPerPage,
        },
      }),
    }),
    createCategory: builder.mutation({
      query: (body) => ({
        url: `/api/category`,
        method: "POST",
        body: body,
      }),
    }),
    updateCategory: builder.mutation({
      query: ({ id, body }) => {
        return {
          url: `/api/category/${id}`,
          method: "PUT",
          body: body,
        };
      },
    }),
    deleteCategory: builder.mutation({
      query: ({ id }) => {
        return {
          url: `api/category/${id}`,
          method: "DELETE",
        };
      },
    }),
  }),
});

export const {
  useGetCategoryQuery,
  useLazyGetCategoryQuery,
  useCreateCategoryMutation,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,
} = CategoryApi;
