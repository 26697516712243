import React from "react";
import { LogoHeader } from "../../assets/images/images";
import { RiMenu3Line } from "react-icons/ri";
const Navbar = ({ onClick }) => {
  return (
    <div className="md:hidden flex items-center justify-between fixed z-50 w-full bg-white p-2 py-3 shadow-lg">
      <img src={LogoHeader} alt={"Count Asset"} className="sm:w-44 w-36" />
      <span
        className="text-4xl cursor-pointer hover:text-primary"
        onClick={onClick}
      >
        <RiMenu3Line />
      </span>
    </div>
  );
};

export default Navbar;
