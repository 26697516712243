import { useState } from "react";
import { Provider } from "react-redux";
import { store } from "./store/store";
import { ConfigProvider } from "antd";
import AppRouter from "./config/router";
import Montserrat from "./assets/fonts/Montserrat-Regular.ttf";
import AppContext from "./context/AppContext";
function App() {
  const [userRole, setUserRole] = useState(null);
  return (
    <Provider store={store}>
      <ConfigProvider
        provider  
        theme={{
          token: {
            colorPrimary: "#04608E",
            fontFamily: Montserrat,
          },
        }}
      >
        <AppContext.Provider value={{ userRole, setUserRole }}>
          <AppRouter />
        </AppContext.Provider>
      </ConfigProvider>
       
    </Provider>
  );
}

export default App;
