import React, { useState, useEffect } from "react";
import { Input, Modal, Spin } from "antd";
import { ThemeButton, ThemeInput, ThemeSelect } from "../components";
import { useLazyGetLocationQuery } from "../../services/locationService";
import { useLazyGetDepartmentQuery } from "../../services/departmentService";

const CheckoutModal = ({
  isOpen,
  onClose,
  title,
  value,
  setValue,
  btnText,
  isLoading,
  onSubmit,
  type,
}) => {
  const [locations, setLocations] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [getLocations] = useLazyGetLocationQuery();
  const [getDepartments] = useLazyGetDepartmentQuery();

  useEffect(() => {
    if (type === "checkin" || type === "checkout") {
      getOptions();
    }
  }, []);

  const getOptions = async () => {
    let locationList = [];
    let departmentList = [];

    const locationResponse = await getLocations({
      currentPage: 1,
      resultPerPage: 10000,
    });

    const departmentResponse = await getDepartments({
      currentPage: 1,
      resultPerPage: 10000,
    });

    locationResponse?.data?.data?.map((location) => {
      locationList.push({ label: location?.name, value: location?._id });
    });

    departmentResponse?.data?.data?.map((department) => {
      departmentList.push({ label: department?.name, value: department?._id });
    });

    setLocations(locationList);
    setDepartments(departmentList);
  };

  return (
    <Modal open={isOpen} footer={false} onCancel={onClose}>
      <h1 className="text-secondary text-xl font-semibold mb-4">{title}</h1>

      {type === "custodian" && (
        <div className="mb-4">
          <ThemeInput
            label={"Custodian"}
            placeholder={"enter custodian name here..."}
            value={value?.value}
            onChange={(e) => setValue({ ...value, value: e.target.value })}
          />
        </div>
      )}
      {(type === "checkin" || type === "checkout" || type === "writeoff") && (
        <div className="mb-4">
          <p className="text-base mb-2">Purpose</p>
          <Input.TextArea
            placeholder="write purpose here..."
            rows={5}
            className="font-medium"
            value={value?.purpose}
            onChange={(e) => setValue({ ...value, purpose: e.target.value })}
          />
        </div>
      )}
      {(type === "checkin" || type === "checkout") && (
        <div>
          <div className="mb-4">
            <ThemeSelect
              label={"Location"}
              placeholder={"select location..."}
              options={locations}
              className={"!h-10 w-full"}
              value={value?.location}
              onChange={(e) => setValue({ ...value, location: e })}
            />
          </div>
          <div className="mb-4">
            <ThemeSelect
              label={"Department"}
              placeholder={"select department..."}
              options={departments}
              className={"!h-10 w-full"}
              value={value?.department}
              onChange={(e) => setValue({ ...value, department: e })}
            />
          </div>
        </div>
      )}

      <ThemeButton
        className={"!rounded-none w-24 !h-10 ml-auto"}
        disabled={isLoading}
        onClick={onSubmit}
        content={
          isLoading ? (
            <Spin className="white-loader" />
          ) : (
            <p className="!text-xs !font-semibold">{btnText}</p>
          )
        }
      />
    </Modal>
  );
};

export default CheckoutModal;
