import React from "react";
import { Drawer, Menu } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { ThemeButton } from "../components";

const MenuDrawer = ({ selected, open, onClose, menuItems, onHandleRoute, onLogout }) => {
  return (
    <Drawer
      placement="right"
      open={open}
      className={"bg-white p-0 sidemenu-drawer"}
      width={200}
      closable={false}
    >
      <div className="flex justify-end  p-4 pb-0">
        <CloseOutlined className="text-lg cursor-pointer" onClick={onClose} />
      </div>
      <Menu
        mode="inline"
        defaultSelectedKeys={[selected]}
        className="bg-white mt-4 w-full"
        items={menuItems}
        onSelect={(items) => onHandleRoute(items?.key)}
      />
       <div className="w-full flex justify-center absolute bottom-5">
        <ThemeButton
          className={"w-10/12 !h-10"}
          onClick={onLogout}
          content={
            <p className="text-lg font-MontserratBold">Logout</p>
          }
        />
      </div>
    </Drawer>
  );
};

export default MenuDrawer;
