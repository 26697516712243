import { jwtDecode } from "jwt-decode";
import {
  CLOUDINARY_URL,
  CLODINARY_CLOUD_NAME,
  CLOUDINARY_PRESET,
} from "../constants/constants";

const uploadImage = async (image) => {
  let url;
  const data = new FormData();
  data.append("file", image);
  data.append("filename", image.name);
  data.append("upload_preset", CLOUDINARY_PRESET);
  data.append("cloud_name", CLODINARY_CLOUD_NAME);
  await fetch(CLOUDINARY_URL, {
    method: "POST",
    body: data,
  })
    .then((res) => res.json())
    .then((data) => {
      url = data.secure_url;
    })
    .catch((err) => {
      err = err;
    });
  return url;
};

const addKeyToStorage = (key, value) => {
  localStorage.setItem(key, value);
};

const deleteKeyFromStorage = (key) => {
  localStorage.removeItem(key);
};

const updateKey = (key, value) => {
  localStorage.setItem(key, value);
};

const getValueFromStorage = (key) => {
  const value = localStorage.getItem(key);
  return value;
};

const getUserInfo = () => {
  try {
    let user = jwtDecode(getValueFromStorage("token"));
    return user;
  } catch (err) {
    return err;
  }
};

const getStatusName = (status) => {
  switch (status) {
    case "active":
      return "Active";
    case "checked_in":
      return "Checked In";
    case "checked_out":
      return "Checked Out";
    case "writed_off":
      return "Writed Off";
    case "asset_added":
      return "Asset Added";
    case "assets_updated":
      return "Asset Updated";
    case "maintanance_done":
      return "Maintenance Done";
    case "location_changed":
      return "Location Changed";
    case "change_custodion":
      return "Change Custodian";
    default:
      return status;
  }
};

export {
  uploadImage,
  addKeyToStorage,
  deleteKeyFromStorage,
  updateKey,
  getValueFromStorage,
  getUserInfo,
  getStatusName
};
