import React, { useState, useEffect } from "react";
import {
  Container,
  ThemeButton,
  ThemeTable,
  CompanyModal,
} from "../../components/components";
import { Form, message, Popconfirm } from "antd";
import { PlusOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  useBlockCompanyMutation,
  useCreateCompanyMutation,
  useGetCompanyQuery,
  useUpdateCompanyMutation,
} from "../../services/companyService";
import dayjs from "dayjs";
import { uploadImage } from "../../helpers/helpers";

const CompanyPage = () => {
  const [form] = Form.useForm();
  const [isOpen, setIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [resultPerPage, setResultPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [mode, setMode] = useState("create");
  const [docId, setDocId] = useState("");
  const [image, setImage] = useState("");
  const [imageFile, setImageFile] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [uploading, setUploading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const { data, isLoading, refetch } = useGetCompanyQuery({
    currentPage: currentPage,
    resultPerPage: resultPerPage,
  });
  const [blockCompany] = useBlockCompanyMutation();

  const [createCompany] = useCreateCompanyMutation();
  const [updateCompany, { isLoading: updating }] = useUpdateCompanyMutation();
  const allowedFormats = ["image/jpeg", "image/png", "image/jpg"];

  useEffect(() => {
    setTotal(data?.data?.length + 1 || 0);
  }, [isLoading]);

  const onClose = () => {
    form.resetFields();
    setImage("");
    setImageFile("");
    setErrorMessage("");
    setIsOpen(false);
    setMode("create");
  };

  const onFinish = async (values) => {
    setSubmitting(true);
    const url = await uploadImage(imageFile);
    let response;

    let body = {
      ...values,
      startDate: values?.startDate
        ? dayjs(values?.startDate).toISOString()
        : "",
      endDate: values?.endDate ? dayjs(values?.endDate).toISOString() : "",
      logo: url,
    };

    if (mode === "create") {
      response = await createCompany(body);
    } else {
      response = await updateCompany({ id: docId, body });
    }
    if (!response?.error) {
      message.success(
        `Company ${mode === "create" ? "added" : "updated"} successfully.`
      );
      onClose();
      refetch();
    } else {
      message.error(
        response?.error?.data?.error || "Please fill form correctly."
      );
    }
  };

  const onBlock = async (id) => {
    try {
      const res = await blockCompany({ id });
      if (!res?.data?.error) {
        message.success("Company deleted successfully");
        refetch();
      }
    } catch (err) {
      message.error(err);
    }
  };

  const onEdit = (data) => {
    setMode("edit");
    form.setFieldsValue({
      ...data,
      startDate: data?.startDate ? dayjs(data?.startDate) : "",
      endDate: data?.endDate ? dayjs(data?.endDate) : "",
    });
    setImage(data?.logo);
    setDocId(data?._id);
    setIsOpen(true);
  };

  const onHandleImage = (e) => {
    const selectedImage = e.target.files[0];
    if (selectedImage) {
      setUploading(true);
      if (allowedFormats.includes(selectedImage.type)) {
        setImage(URL.createObjectURL(selectedImage));
        setImageFile(selectedImage);
        setErrorMessage("");
        setUploading(false);
      } else {
        setImage("");
        setImageFile("");
        setErrorMessage(
          "Invalid file format. Please select a JPEG, PNG or JPG image."
        );
        setUploading(false);
      }
    }
  };

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: "10%",
      render: (data, _, index) => (
        <p className="text-center font-medium">{index + 1}</p>
      ),
    },
    {
      title: "Logo",
      dataIndex: "logo",
      key: "logo",
      render: (text) => (
        <div className="flex justify-center items-center">
          {
            <img
              src={text}
              alt="icon"
              className="w-10 h-10 rounded-full border border-primary object-contain"
            />
          }
        </div>
      ),
    },
    {
      title: "Company Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <p className="font-medium text-center">{text}</p>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text) => <p className="font-medium text-center">{text}</p>,
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      render: (text) => (
        <p className="font-medium text-center">
          {text && dayjs(text).format("YYYY-MMM-DD")}
        </p>
      ),
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      render: (text) => (
        <p className="font-medium text-center">
          {text && dayjs(text).format("YYYY-MMM-DD")}
        </p>
      ),
    },
    {
      title: "In Action",
      dataIndex: "_id",
      key: "_id",
      width: "10%",
      render: (text, data) => (
        <span className="flex justify-center items-center">
          <span
            className="mx-2 cursor-pointer text-base hover:text-primary"
            onClick={() => onEdit(data)}
          >
            <EditOutlined />
          </span>
          <Popconfirm
            title="Delete the Company"
            description="Are you sure to delete this company?"
            onConfirm={() => onBlock(data?._id)}
            okText="Yes"
            cancelText="No"
            placement="left"
            className="ml-2"
          >
            <DeleteOutlined className="hover:text-red-600" />
          </Popconfirm>
        </span>
      ),
    },
  ];

  return (
    <Container selected={"7"}>
      <div className="flex items-center justify-between">
        <p className="text-2xl font-MontserratBold">Companies</p>
        <ThemeButton
          content={
            <div className="flex items-center justify-center">
              <PlusOutlined />
              <p className="ml-2">Add Company</p>
            </div>
          }
          onClick={() => setIsOpen(true)}
        />
      </div>
      <div className="mt-6 overflow-x-auto">
        <ThemeTable
          columns={columns}
          data={data?.data || []}
          loader={isLoading}
          pagination={{ total, setCurrentPage, setResultPerPage }}
        />
      </div>
      <CompanyModal
        isOpen={isOpen}
        onClose={onClose}
        form={form}
        image={image}
        onHandleImage={(e) => onHandleImage(e)}
        uploading={uploading}
        errorMessage={errorMessage}
        onFinish={onFinish}
        isLoading={mode === "create" ? submitting : updating}
        mode={mode}
      />
    </Container>
  );
};

export default CompanyPage;
