import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../constants/constants";
import { getValueFromStorage } from "../helpers/helpers";

export const CompanyApi = createApi({
  reducerPath: "companies",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      headers.set("Content-type", "application/json");
      headers.set("authorization", `Bearer ${getValueFromStorage("token")}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getCompany: builder.query({
      query: ({ currentPage = 1, resultPerPage = 10000 }) => ({
        url: `/api/company`,
        method: "GET",
        params: {
          current_page: currentPage,
          result_per_page: resultPerPage,
        },
      }),
    }),
    createCompany: builder.mutation({
      query: (body) => ({
        url: `/api/company`,
        method: "POST",
        body: body,
      }),
    }),
    updateCompany: builder.mutation({
      query: ({ id, body }) => {
        return {
          url: `/api/company/${id}`,
          method: "PUT",
          body: body,
        };
      },
    }),
    blockCompany: builder.mutation({
      query: ({ id }) => {
        return {
          url: `api/company/block/${id}`,
          method: "PUT",
        };
      },
    }),
  }),
});

export const {
  useGetCompanyQuery,
  useLazyGetCompanyQuery,
  useCreateCompanyMutation,
  useUpdateCompanyMutation,
  useBlockCompanyMutation,
} = CompanyApi;
