import React, { useEffect, useRef, useState } from "react";
import {
  AssetInfo,
  Container,
  CheckoutModal,
  MaintenanceModal,
  TransferModal,
  ThemeButton,
  ThemeTable,
} from "../../components/components";
import { Col, Row, Skeleton, Tabs, message, Form, Alert } from "antd";
import { FaArrowLeftLong } from "react-icons/fa6";
import {
  useCheckinAssetMutation,
  useCheckoutAssetMutation,
  useLazyGetAssetByIdQuery,
  useMaintenanceAssetMutation,
  useTransferAssetMutation,
  useUpdateCustodianMutation,
  useWriteoffAssetMutation,
} from "../../services/assetService";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useLazyGetTransactionByIdQuery } from "../../services/transactionService";
import dayjs from "dayjs";
import { getStatusName } from "../../helpers/helpers";

const AssetDetail = () => {
  const formRef = useRef();
  const [transferForm] = Form.useForm();
  const { id } = useParams();
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const [selectedKey, setSelectedKey] = useState(tab || "1");
  const [assetData, setAssetData] = useState({});
  const [isOpenCheckoutModal, setIsOpenCheckoutModal] = useState(false);
  const [isOpenMaintenanceModal, setIsOpenMaintenanceModal] = useState(false);
  const [isOpenTransferModal, setIsOpenTransferModal] = useState(false);
  const [value, setValue] = useState({});
  const [checkModalType, setCheckModalType] = useState("checkout");
  const [transactions, setTransactions] = useState([]);
  const [getAssetById, { isLoading }] = useLazyGetAssetByIdQuery();
  const [checkoutAsset, { isLoading: checkoutLoader }] =
    useCheckoutAssetMutation();
  const [checkinAsset, { isLoading: checkinLoader }] =
    useCheckinAssetMutation();
  const [writeoffAsset, { isLoading: writeoffLoader }] =
    useWriteoffAssetMutation();
  const [transferAsset, { isLoading: transferLoader }] =
    useTransferAssetMutation();
  const [maintenanceAsset, { isLoading: maintenanceLoader }] =
    useMaintenanceAssetMutation();
  const [updateCustodian, { isLoading: custodianLoader }] =
    useUpdateCustodianMutation();
  const [getTransactionById, { isLoading: fetchingTransactionLoader }] =
    useLazyGetTransactionByIdQuery();

  useEffect(() => {
    if (tab != 2) {
      getAsset();
    } else if (tab == 2) {
      getTransactions();
    }
  }, [tab]);

  const getAsset = async () => {
    const response = await getAssetById({ id });
    setAssetData(response?.data?.data);
  };

  const getTransactions = async () => {
    const response = await getTransactionById(id);
    setTransactions(response?.data?.data || []);
  };

  const onChange = (key) => {
    setSearchParams({ tab: key });
    setSelectedKey(key);
  };

  const getModalType = (type) => {
    switch (checkModalType) {
      case "checkout":
        return type === "title"
          ? "Check out Asset"
          : type === "btnText"
          ? "Check out"
          : type === "loader"
          ? checkoutLoader
          : type === "successMessage"
          ? "Asset check out successfully"
          : type === "errorMessage"
          ? "Asset check out failed"
          : "";
      case "checkin":
        return type === "title"
          ? "Check in Asset"
          : type === "btnText"
          ? "Check in"
          : type === "loader"
          ? checkinLoader
          : type === "successMessage"
          ? "Asset check in successfully"
          : type === "errorMessage"
          ? "Asset check in failed"
          : "";
      case "writeoff":
        return type === "title"
          ? "Write off Asset"
          : type === "btnText"
          ? "Write off"
          : type === "loader"
          ? writeoffLoader
          : type === "successMessage"
          ? "Asset Write off successfully"
          : type === "errorMessage"
          ? "Asset Write off failed"
          : "";
      case "custodian":
        return type === "title"
          ? "Update Custodian"
          : type === "btnText"
          ? "Update"
          : type === "loader"
          ? custodianLoader
          : type === "successMessage"
          ? "Custodian updated successfully"
          : type === "errorMessage"
          ? "Custodian update failed"
          : "";
      default:
        return checkModalType;
    }
  };

  const onCloseCheckoutModal = () => {
    setValue("");
    setIsOpenCheckoutModal(false);
  };

  const onCloseMaintenanceModal = () => {
    setIsOpenMaintenanceModal(false);
    formRef.current.resetFields();
  };

  const onCloseTransferModal = () => {
    setIsOpenTransferModal(false);
    transferForm.resetFields();
  };

  const onSubmit = async () => {
    let response;
    if (checkModalType === "checkout") {
      response = await checkoutAsset({
        id: assetData?.assetCode,
        body: { ...value },
      });
    } else if (checkModalType === "checkin") {
      response = await checkinAsset({
        id: assetData?.assetCode,
        body: { ...value },
      });
    } else if (checkModalType === "writeoff") {
      response = await writeoffAsset({
        id: assetData?.assetCode,
        body: { ...value },
      });
    } else if (checkModalType === "custodian") {
      response = await updateCustodian({
        id,
        body: { custodian: value?.value },
      });
    }

    if (response?.data?.status?.code === 200) {
      message.success(getModalType("successMessage"));
      onCloseCheckoutModal();
      getAsset();
    } else {
      message.error(response?.data?.error?.msg || getModalType("errorMessage"));
    }
  };

  const onFinishTransferModal = async (values) => {
    const response = await transferAsset({
      id: assetData?.assetCode,
      body: { ...values },
    });
    if (response?.data?.status?.code === 200) {
      message.success("Asset transfer successfully");
      onCloseTransferModal();
      getAsset();
    } else {
      message.error(response?.data?.error?.msg || "Asset transfer failed");
    }
  };

  const onFinishMaintenanceModal = async (values) => {
    const response = await maintenanceAsset({ id,
       body: { ...values ,
        maintenanceCost: Number(values?.maintenanceCost),
        timestamp: values?.maintenanceDate
        ? dayjs(values?.maintenanceDate).toISOString():"",
        dueMaintenanceDate: values?.dueMaintenanceDate
        ? dayjs(values?.nextMaintenanceDate).toISOString():""
      }
    }
    );

    console.log("response", response);

    if (response?.data?.status?.code === 200) {
      message.success("Submit successfully");
      onCloseMaintenanceModal();
      setIsOpenMaintenanceModal(false);
    } else {
      message.error("Something went wrong!");
    }
  };

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: "10%",
      render: (data, _, index) => (
        <p className="text-center font-medium">{index + 1}</p>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text) => <p className="font-medium text-center">{getStatusName(text)}</p>,
    },
    {
      title: "Purpose",
      dataIndex: "purpose",
      key: "purpose",
      render: (text) => <p className="font-medium text-center">{text}</p>,
    },
    {
      title: "Timestamp",
      dataIndex: "timestamp",
      key: "timestamp",
      render: (text) => (
        <p className="font-medium text-center">
          {dayjs(text).format("YYYY-MMM-DD hh:mm:ss A")}
        </p>
      ),
    },
  ];

  const items = [
    {
      key: "1",
      label: (
        <p className={`${selectedKey === "1" && "font-semibold"}`}>
          Asset Info
        </p>
      ),
      children: (
        <div>
          {isLoading ? (
            <div className="bg-white rounded-xl p-5 mb-8">
              <Skeleton />
            </div>
          ) : (
            <AssetInfo data={assetData} />
          )}
        </div>
      ),
    },

    {
      key: "2",
      label: (
        <p className={`${selectedKey === "1" && "font-semibold"}`}>History</p>
      ),
      children: (
        <div>
          <ThemeTable
            columns={columns}
            data={transactions}
            loader={fetchingTransactionLoader}
          />
        </div>
      ),
    },
  ];

  return (
    <Container selected={"5"}>
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <span
            className="text-xl cursor-pointer"
            onClick={() => navigate("/asset")}
          >
            <FaArrowLeftLong />
          </span>
          <p className="ml-3 font-MontserratBold text-2xl">
            {assetData?.assetName}
          </p>
        </div>
      </div>

      {assetData?.status !== "writed_off" ? (
      <Row className="justify-end" gutter={{ xs: 8, sm: 16, md: 20, lg: 20 }}>
        {assetData?.status !== "Checked Out" && (
          <Col xl={4} lg={4} md={5} sm={8} xs={8} className="my-2">
            <ThemeButton
              content={"Check out"}
              className={"mx-1 w-full !h-9"}
              onClick={() => {
                setCheckModalType("checkout");
                setIsOpenCheckoutModal(true);
              }}
            />
          </Col>
        )}
        {assetData?.status !== "checked_in" && (
          <Col xl={4} lg={4} md={5} sm={8} xs={8} className="my-2">
            <ThemeButton
              content={"Check in"}
              className={"mx-1 w-full !h-9"}
              onClick={() => {
                setCheckModalType("checkin");
                setIsOpenCheckoutModal(true);
              }}
            />
          </Col>
        )}
        <Col xl={4} lg={4} md={5} sm={8} xs={8} className="my-2">
          <ThemeButton
            content={"Write Off"}
            className={"mx-1 w-full !h-9"}
            onClick={() => {
              setCheckModalType("writeoff");
              setIsOpenCheckoutModal(true);
            }}
          />
        </Col>
        <Col xl={4} lg={4} md={5} sm={8} xs={8} className="my-2">
          <ThemeButton
            content={"Transfer"}
            className={"mx-1 w-full !h-9"}
            onClick={() => {
              transferForm.setFieldsValue({
                newLocation: assetData?.location,
                newDepartment: assetData?.newDepartment,
                custodian: assetData?.custodian,
              });
              setIsOpenTransferModal(true);
            }}
          />
        </Col>
        <Col xl={4} lg={5} md={7} sm={12} xs={12} className="my-2">
          <ThemeButton
            content={"Update custodian"}
            className={"mx-1 w-full !h-9"}
            onClick={() => {
              setValue(assetData?.custodian);
              setCheckModalType("custodian");
              setIsOpenCheckoutModal(true);
            }}
          />
        </Col>
        <Col xl={4} lg={5} md={6} sm={12} xs={12} className="my-2">
          <ThemeButton
            content={"Maintenance"}
            className={"mx-1 w-full !h-9"}
            onClick={() => setIsOpenMaintenanceModal(true)}
          />
        </Col>
      </Row>
      ) : (
        <Alert type="error" message="This Asset is Write off" className="my-2 text-sm"/>
      )}

      <Tabs
        defaultActiveKey={searchParams.get("tab") || "1"}
        items={items}
        onChange={onChange}
      />

      <CheckoutModal
        isOpen={isOpenCheckoutModal}
        onClose={onCloseCheckoutModal}
        title={getModalType("title")}
        value={value}
        setValue={(e) => setValue(e)}
        btnText={getModalType("btnText")}
        isLoading={getModalType("loader")}
        onSubmit={onSubmit}
        type={checkModalType}
      />
      <TransferModal
        isOpen={isOpenTransferModal}
        onClose={onCloseTransferModal}
        form={transferForm}
        onFinish={onFinishTransferModal}
        isLoading={transferLoader}
      />
      <MaintenanceModal
        isOpen={isOpenMaintenanceModal}
        onClose={onCloseMaintenanceModal}
        formRef={formRef}
        onFinish={onFinishMaintenanceModal}
        isLoading={maintenanceLoader}
      />
    </Container>
  );
};

export default AssetDetail;
