import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../constants/constants";
import { getValueFromStorage } from "../helpers/helpers";

export const DepartmentApi = createApi({
  reducerPath: "departments",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      headers.set("Content-type", "application/json");
      headers.set("authorization", `Bearer ${getValueFromStorage("token")}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getDepartment: builder.query({
      query: ({ currentPage = 1, resultPerPage = 10000 }) => ({
        url: `/api/departments`,
        method: "GET",
        params: {
          current_page: currentPage,
          result_per_page: resultPerPage,
        },
      }),
    }),
    createDepartment: builder.mutation({
      query: (body) => ({
        url: `/api/departments`,
        method: "POST",
        body: body,
      }),
    }),
    updateDepartment: builder.mutation({
      query: ({ id, body }) => {
        return {
          url: `/api/departments/${id}`,
          method: "PUT",
          body: body,
        };
      },
    }),
    deleteDepartment: builder.mutation({
      query: ({ id }) => {
        return {
          url: `api/departments/${id}`,
          method: "DELETE",
        };
      },
    }),
  }),
});

export const {
  useGetDepartmentQuery,
  useLazyGetDepartmentQuery,
  useCreateDepartmentMutation,
  useUpdateDepartmentMutation,
  useDeleteDepartmentMutation,
} = DepartmentApi;
