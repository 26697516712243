import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query/react";
import {
  AssetApi,
  AuthApi,
  DepartmentApi,
  GroupApi,
  LocationApi,
  TransactionApi,
  StatsApi,
  CategoryApi,
  CompanyApi,
  UserApi,
} from "../services/services";

export const store = configureStore({
  reducer: {
    [AuthApi.reducerPath]: AuthApi.reducer,
    [GroupApi.reducerPath]: GroupApi.reducer,
    [CategoryApi.reducerPath]: CategoryApi.reducer,
    [LocationApi.reducerPath]: LocationApi.reducer,
    [DepartmentApi.reducerPath]: DepartmentApi.reducer,
    [AssetApi.reducerPath]: AssetApi.reducer,
    [TransactionApi.reducerPath]: TransactionApi.reducer,
    [StatsApi.reducerPath]: StatsApi.reducer,
    [CompanyApi.reducerPath]: CompanyApi.reducer,
    [UserApi.reducerPath]: UserApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      AuthApi.middleware,
      GroupApi.middleware,
      CategoryApi.middleware,
      LocationApi.middleware,
      DepartmentApi.middleware,
      AssetApi.middleware,
      TransactionApi.middleware,
      StatsApi.middleware,
      CompanyApi.middleware,
      UserApi.middleware
    ),
});

setupListeners(store.dispatch);
