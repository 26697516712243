import React, { useEffect, useState } from "react";
import { Container, ThemeTable } from "../../components/components";
import { FaArrowLeftLong } from "react-icons/fa6";
import dayjs from "dayjs";
import { useGetTransactionQuery } from "../../services/transactionService";
import { NavLink } from "react-router-dom";
import { getStatusName } from "../../helpers/helpers";

const Transactions = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [resultPerPage, setResultPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const { data, isLoading } = useGetTransactionQuery({
    currentPage: currentPage,
    resultPerPage: resultPerPage,
  });

  useEffect(() => {
    setTotal(data?.data?.totalDocs)
  }, [isLoading]);

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: "10%",
      render: (data, _, index) => (
        <p className="text-center font-medium">{index + 1}</p>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text) => <p className="font-medium text-center">{getStatusName(text)}</p>,
    },
    {
      title: "Purpose",
      dataIndex: "purpose",
      key: "purpose",
      render: (text) => <p className="font-medium text-center">{text}</p>,
    },
    {
      title: "Timestamp",
      dataIndex: "timestamp",
      key: "timestamp",
      render: (text) => (
        <p className="font-medium text-center">
          {dayjs(text).format("YYYY-MMM-DD hh:mm:ss A")}
        </p>
      ),
    },
  ];

  return (
    <Container selected={"1"}>
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <NavLink to="/dashboard">
            <span className="text-2xl cursor-pointer">
              <FaArrowLeftLong />
            </span>
          </NavLink>
          <p className="ml-3 font-MontserratBold text-2xl">Transactions</p>
        </div>
      </div>

      <div className="mt-6">
        <ThemeTable
          columns={columns}
          data={data?.data?.docs || []}
          loader={isLoading}
          pagination={{ total, setCurrentPage, setResultPerPage }}
        />
      </div>
    </Container>
  );
};

export default Transactions;
