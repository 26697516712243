import React, { useState, useEffect } from "react";
import {
  Container,
  ThemeButton,
  ThemeTable,
  UserModal,
} from "../../components/components";
import { Form, message, Popconfirm } from "antd";

import { PlusOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  useDeleteUserMutation,
  useCreateUserMutation,
  useGetUserQuery,
  useUpdateUserMutation,
} from "../../services/userService";

const Users = () => {
  const [form] = Form.useForm();
  const [isOpen, setIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [resultPerPage, setResultPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [mode, setMode] = useState("create");
  const [docId, setDocId] = useState("");
  const [role, setRole] = useState("");
  const [location, setLocation] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [uploading, setUploading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const { data, isLoading, refetch } = useGetUserQuery({
    currentPage: currentPage,
    resultPerPage: resultPerPage,
  });
  const [deleteUser] = useDeleteUserMutation();

  const [createUser] = useCreateUserMutation();
  const [updateUser, { isLoading: updating }] = useUpdateUserMutation();

  useEffect(() => {
    setTotal(data?.data?.length + 1 || 0);
  }, [isLoading]);

  const onEdit = (data) => {
    setMode("edit");
    form.setFieldsValue({
      ...data,
    });
    setDocId(data?._id);
    setIsOpen(true);
  };
  const onDelete = async (id) => {
    try {
      const res = await deleteUser({ id });
      if (!res?.data?.error) {
        message.success("User deleted successfully");
        refetch();
      }
    } catch (err) {
      message.error(err);
    }
  };
  const onClose = () => {
    form.resetFields();
    setErrorMessage("");
    setIsOpen(false);
    setMode("create");
  };

  const onFinish = async (values) => {
    console.log("values--->",values);
    // console.log("body",body);
    setSubmitting(true);
  
    let response;

    let body = {
      ...values,
      role,
      location
    };

    if (mode === "create") {
      response = await createUser(body);
    } else {
      response = await updateUser({ id: docId, body });
    }
    if (!response?.error) {
      message.success(
        `User ${mode === "create" ? "added" : "updated"} successfully.`
      );
      onClose();
      refetch();
    } else {
      message.error(
        response?.error?.data?.error || "Please fill form correctly."
      );
    }
setSubmitting(false)
  };
  const roles = [
    { value: "location_admin", label: "Location Admin" },
    { value: "tracking_admin", label: "Tracking Admin" },
  ];
  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: "10%",
      render: (data, _, index) => (
        <p className="text-center font-medium">{index + 1}</p>
      ),
    },

    {
      title: "User Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <p className="font-medium text-center">{text}</p>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text) => <p className="font-medium text-center">{text}</p>,
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (text) => <p className="font-medium text-center">{text}</p>,
    },
    {
      title: "In Action",
      dataIndex: "_id",
      key: "_id",
      width: "10%",
      render: (text, data) => (
        <span className="flex justify-center items-center">
          <span
            className="mx-2 cursor-pointer text-base hover:text-primary"
            onClick={() => onEdit(data)}
          >
            <EditOutlined />
          </span>
          <Popconfirm
            title="Delete the User"
            description="Are you sure to delete this User?"
            onConfirm={() => onDelete(data?._id)}
            okText="Yes"
            cancelText="No"
            placement="left"
            className="ml-2"
          >
            <DeleteOutlined className="hover:text-red-600" />
          </Popconfirm>
        </span>
      ),
    },
  ];
  return (
    <Container selected={8}>
      <div className="flex items-center justify-between">
        <p className="text-2xl font-MontserratBold">Users</p>
        <ThemeButton
          content={
            <div className="flex items-center justify-center">
              <PlusOutlined />
              <p className="ml-2">Add User</p>
            </div>
          }
          onClick={() => setIsOpen(true)}
        />
      </div>
      <div className="mt-6 overflow-x-auto">
        <ThemeTable
          columns={columns}
          data={data?.data || []}
          loader={isLoading}
          pagination={{ total, setCurrentPage, setResultPerPage }}
        />
      </div>
      <UserModal
        isOpen={isOpen}
        onClose={onClose}
        form={form}
        uploading={uploading}
        errorMessage={errorMessage}
        onFinish={onFinish}
        isLoading={mode === "create" ? submitting : updating}
        mode={mode}
        roles={roles}
        role={role}
        setRole={setRole}
        setLocation={setLocation}
      />
    </Container>
  );
};

export default Users;
