import React, { useState, useEffect } from "react";
import {
  Container,
  ThemeButton,
  ThemeInput,
  ThemeSelect,
  ThemeTable,
} from "../../components/components";
import { PlusOutlined } from "@ant-design/icons";
import categoryOptions from "../../constants/data/options.json";
import {
  MdOutlineEdit,
  MdOutlineDeleteOutline,
  MdOutlineRemoveRedEye,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useGetAssetMutation } from "../../services/assetService";
import { image_icon } from "../../assets/icons/icons";
import { useLazyGetLocationQuery } from "../../services/locationService";
import { useLazyGetDepartmentQuery } from "../../services/departmentService";
import { useLazyGetGroupQuery } from "../../services/groupService";
import { Button, DatePicker, Spin } from "antd";
import { getStatusName } from "../../helpers/helpers";
import * as XLSX from "xlsx";

const AssetRegister = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [resultPerPage, setResultPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [assets, setAssets] = useState([]);
  const [groups, setGroups] = useState([]);
  const [locations, setLocations] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [getLocations] = useLazyGetLocationQuery();
  const [getDepartments] = useLazyGetDepartmentQuery();
  const [getGroups] = useLazyGetGroupQuery();
  const [filters, setFilters] = useState({});
  const [getAssets, { isLoading }] = useGetAssetMutation();
  const [clear, setClear] = useState(false);

  useEffect(() => {
    fetchAssets();
  }, [currentPage, resultPerPage]);

  useEffect(() => {
    if (clear) {
      fetchAssets();
    }
  }, [clear]);

  const fetchAssets = async () => {
    const response = await getAssets({
      filters: filters,
      page: currentPage,
      limit: resultPerPage,
    });
    setTotal(response?.data?.data?.total);
    setAssets(response?.data?.data?.assets || []);
  };

  useEffect(() => {
    getSelectOptions();
  }, []);

  const getSelectOptions = async () => {
    let groupList = [];
    let locationList = [];
    let departmentList = [];

    const groupResponse = await getGroups({
      currentPage: 1,
      resultPerPage: 10000,
    });
    const locationResponse = await getLocations({
      currentPage: 1,
      resultPerPage: 10000,
    });
    const departmentResponse = await getDepartments({
      currentPage: 1,
      resultPerPage: 10000,
    });

    groupResponse?.data?.data?.map((group) => {
      groupList.push({ label: group?.name, value: group?._id });
    });

    locationResponse?.data?.data?.map((location) => {
      locationList.push({ label: location?.name, value: location?._id });
    });

    departmentResponse?.data?.data?.map((department) => {
      departmentList.push({ label: department?.name, value: department?._id });
    });

    setGroups(groupList);
    setLocations(locationList);
    setDepartments(departmentList);
  };

  const handleExport = () => {
    let assetsList = [];

    assets.forEach((asset) => {
      assetsList.push({
        assetName: asset?.assetName,
        assetDescription: asset?.assetDescription,
        group: asset?.groupDetails?.name,
        category: asset?.category,
        type: asset?.type,
        brand: asset?.brand,
        vendor: asset?.vendor,
        location: asset?.locationDetails?.name,
        department: asset?.departmentDetails?.name,
        custodian: asset?.custodian,
        price: asset?.price,
        usefulLife: asset?.useefulLife,
        salvageValue: asset?.salvageValue,
        status: getStatusName(asset?.status),
      });
    });

    let wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(assetsList);

    XLSX.utils.book_append_sheet(wb, ws, "MySheet1");

    XLSX.writeFile(wb, "Assets.xlsx");
  };

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: "4%",
      render: (data, _, index) => (
        <p className="text-center font-medium">{index + 1}</p>
      ),
    },
    {
      title: "Picture",
      dataIndex: "picture",
      key: "picture",
      width: "8%",
      render: (text) => (
        <div className="flex items-center justify-center">
          {text ? (
            <img
              src={text}
              alt="icon"
              onError={(e) => {
                e.target.src = image_icon; // Set the placeholder icon if the image fails to load
              }}
              className="w-10 h-10 rounded-full border border-primary object-contain"
            />
          ) : (
            <img
              src={image_icon}
              alt="icon"
              className="w-10 h-10 rounded-full border border-primary object-cover"
            />
          )}
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "assetName",
      key: "assetName",
      render: (text) => <p className="font-medium text-center">{text}</p>,
    },
    {
      title: "Group",
      dataIndex: "groupDetails",
      key: "groupDetails",
      render: (text) => <p className="font-medium text-center">{text?.name}</p>,
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (text) => <p className="font-medium text-center">{text}</p>,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (text) => <p className="font-medium text-center">{text}</p>,
    },
    {
      title: "Vendor",
      dataIndex: "vendor",
      key: "vendor",
      render: (text) => <p className="font-medium text-center">{text}</p>,
    },
    {
      title: "Location",
      dataIndex: "locationDetails",
      key: "locationDetails",
      render: (text) => <p className="font-medium text-center">{text?.name}</p>,
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (text) => <p className="font-medium text-center">{text}</p>,
    },
    {
      title: "Asset Code",
      dataIndex: "assetCode",
      key: "assetCode",
      render: (text) => <p className="font-medium text-center">{text}</p>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => (
        <p className="font-medium text-center">{getStatusName(text)}</p>
      ),
    },
    {
      title: "In Action",
      dataIndex: "_id",
      key: "_id",
      width: "10%",
      render: (text, data) => (
        <span className="flex justify-center items-center">
          <span
            className="mx-2 cursor-pointer text-lg hover:text-primary"
            onClick={() => navigate(data?._id)}
          >
            <MdOutlineEdit />
          </span>
          <span
            className="mx-2 cursor-pointer text-lg hover:text-red-600"
            // onClick={() => onDelete(data?._id)}
          >
            <MdOutlineDeleteOutline />
          </span>
          <span
            className="mx-2 cursor-pointer text-lg hover:text-primary"
            onClick={() => navigate(`detail/${data?._id}`)}
          >
            <MdOutlineRemoveRedEye />
          </span>
        </span>
      ),
    },
  ];

  const status = [
    { value: "checked_in", label: "Checked In" },
    { value: "Checked_out", label: "Checked Out" },
    { value: "writed_off", label: "Writed Off" },
    { value: "transfer", label: "transfer" },
    { value: "custodian", label: "custodian" },
  ];

  return (
    <Container selected={"5"}>
      <div className="flex items-center justify-between">
        <p className="text-2xl font-MontserratBold">Assets</p>
        <ThemeButton
          content={
            <div className="flex items-center justify-center hover:text-primary">
              <PlusOutlined />
              <p className="ml-2">Add Asset</p>
            </div>
          }
          onClick={() => navigate("add")}
        />
      </div>

      <div className="flex flex-col mt-3">
        <div className="flex items-center flex-wrap">
          <ThemeInput
            placeholder={"code..."}
            inputClassName={"!h-8 w-40"}
            inputHeight={"h-8"}
            value={filters?.assetCode}
            onChange={(e) =>
              setFilters({ ...filters, assetCode: e.target.value })
            }
          />
          <ThemeSelect
            placeholder={"group..."}
            options={groups}
            className={"!h-8 ml-2 w-40"}
            value={filters?.group}
            onChange={(e) => setFilters({ ...filters, group: e })}
          />
          <ThemeSelect
            placeholder={"category..."}
            options={categoryOptions}
            className={"!h-8 ml-2 w-40"}
            value={filters?.category}
            onChange={(e) => setFilters({ ...filters, category: e })}
          />
          <ThemeSelect
            placeholder={"location..."}
            options={locations}
            className={"!h-8 ml-2 w-40"}
            value={filters?.location}
            onChange={(e) => setFilters({ ...filters, location: e })}
          />
          <ThemeSelect
            placeholder={"department..."}
            options={departments}
            className={"!h-8 ml-2 w-40"}
            value={filters?.department}
            onChange={(e) => setFilters({ ...filters, department: e })}
          />
          <ThemeSelect
            placeholder={"status..."}
            options={status}
            className={"!h-8 ml-2 w-40"}
            value={filters?.status}
            onChange={(e) => setFilters({ ...filters, status: e })}
          />
        </div>
        <div className="mt-2 flex items-center">
          <ThemeButton
            content={"Filter"}
            onClick={fetchAssets}
            disabled={isLoading}
          />
          <Button
            onClick={() => {
              setFilters();
              setClear(true);
            }}
            className="ml-2"
          >
            Clear
          </Button>
          <ThemeButton content={"Export"} onClick={handleExport} className={"ml-2"} />
        </div>
      </div>

      <div className="my-6">
        <ThemeTable
          columns={columns}
          data={assets}
          loader={isLoading}
          pagination={{ total, setCurrentPage, setResultPerPage }}
          scroll={{
            x: 1000,
          }}
          size
        />
      </div>
    </Container>
  );
};

export default AssetRegister;
