import React, { useState,useEffect } from "react";
import { Col, Form, Modal, Row, Spin, DatePicker } from "antd";
import { ThemeButton, ThemeInput, Uploader ,ThemeSelect} from "../components";
import { useLazyGetLocationQuery } from "../../services/locationService";

const UserModal = ({
  isOpen,
  onClose,
  onFinish,
  form,
  errorMessage,
  uploading,
  isLoading,
  mode,
  roles,
  setRole,
  setLocation
}) => {
  const [locations, setLocations] = useState([]);
  const [getLocations] = useLazyGetLocationQuery();
  useEffect(() => {
    getOptions();
  }, []);
  const getOptions = async () => {
    let locationList = [];
    const locationResponse = await getLocations({
      currentPage: 1,
      resultPerPage: 10000,
    });
    locationResponse?.data?.data?.map((location) => {
      locationList.push({ label: location?.name, value: location?._id });
    });


    setLocations(locationList);

  };
  return (
    <Modal
      open={isOpen}
      footer={false}
      onCancel={onClose}
      width={800}
      style={{ top: 0 }}
    >
      <h1 className="text-secondary text-xl font-semibold mb-4">
        {mode === "create" ? "Add user" : "Update user"}
      </h1>
      <Form
        name="UserForm"
        form={form}
        autoComplete="true"
        onFinish={onFinish}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 20, lg: 20 }}>
          
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            <Form.Item
              name="name"
              rules={[{ required: true, message: "user name is required!" }]}
            >
              <ThemeInput
                label={"Name"}
                type={"text"}
                placeholder={"enter user name here..."}
                inputClassName={"h-10"}
              />
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            <Form.Item
              name="email"
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                {
                  required: true,
                  message: `email is required!`,
                },
              ]}
            >
              <ThemeInput
                label={"Email"}
                type={"email"}
                disabled={mode === "edit"}
                placeholder={"enter user email here..."}
                inputClassName={"h-10"}
              />
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: `password is required!`,
                },
              ]}
            >
              <ThemeInput
                label={"Password"}
                password
                placeholder={"enter password here..."}
                inputClassName={"h-10"}
              />
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
          <ThemeSelect
            placeholder={"role"}
            label={"Role"}
            options={roles}
            className={"!h-10 ml-2 w-full "}
          
            onChange={(e) => setRole(e)}
          />
          </Col>
            
            
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
          <ThemeSelect
            placeholder={"location"}
            label={"location"}
            options={locations}
            className={"!h-10 ml-2 w-full"}
          
            onChange={(e) => setLocation(e)}
          />
          </Col>
         
        </Row>

        <Form.Item className="flex justify-end mt-8 mb-0 w-full">
          <ThemeButton
            htmlType="submit"
            className={"!rounded-none w-32 !h-10"}
            disabled={isLoading}
            content={
              isLoading ? (
                <Spin className="white-loader" />
              ) : (
                <p className="!text-xs !font-medium">
                  {mode === "create" ? "ADD USER" : "UPDATE USER"}
                </p>
              )
            }
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UserModal;
