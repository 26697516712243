import React, { useState, useEffect, useRef } from "react";
import {
  DepartmentModal,
  Container,
  ThemeButton,
  ThemeTable,
} from "../../components/components";
import { PlusOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { message, Popconfirm, Form } from "antd";
import {
  useGetDepartmentQuery,
  useCreateDepartmentMutation,
  useUpdateDepartmentMutation,
  useDeleteDepartmentMutation,
} from "../../services/departmentService";

const DepartmentPage = () => {
  const [form] = Form.useForm();
  const [isOpen, setIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [resultPerPage, setResultPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [mode, setMode] = useState("create");
  const [initialFormData, setInitialFormData] = useState({});
  const [docId, setDocId] = useState("");
  const { data, isLoading, refetch } = useGetDepartmentQuery({
    currentPage: currentPage,
    resultPerPage: resultPerPage,
  });
  const [deleteDepartment] = useDeleteDepartmentMutation();

  const [createDepartment, { isLoading: submitting }] =
    useCreateDepartmentMutation();
  const [updateDepartment, { isLoading: updating }] =
    useUpdateDepartmentMutation();

  const onClose = () => {
    form.resetFields();
    setIsOpen(false);
    setMode("create");
  };

  const onFinish = async (values) => {
    let response;
    if (mode === "create") {
      response = await createDepartment({ ...values });
    } else {
      response = await updateDepartment({ id: docId, body: { ...values } });
    }
    console.log("resp", response);
    if (!response?.error) {
      message.success(
        `Department ${mode === "create" ? "added" : "updated"} successfully.`
      );
      onClose();
      refetch();
    } else {
      message.error(
        response?.error?.data?.error || "Please fill form correctly."
      );
    }
  };

  const onDelete = async (id) => {
    try {
      const res = await deleteDepartment({ id });
      if (!res?.data?.error) {
        message.success("Department deleted successfully");
        refetch();
      }
    } catch (err) {
      message.error(err);
    }
  };

  const onEdit = (data) => {
    setMode("edit");
    form.setFieldsValue({
      ...data,
    });
    setDocId(data?._id);
    setIsOpen(true);
  };

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: "10%",
      render: (data, _, index) => (
        <p className="text-center font-medium">{index + 1}</p>
      ),
    },
    {
      title: "Department Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <p className="font-medium text-center">{text}</p>,
    },
    {
      title: "In Action",
      dataIndex: "_id",
      key: "_id",
      width: "10%",
      render: (text, data) => (
        <span className="flex justify-center items-center">
          <span
            className="mx-2 cursor-pointer text-base hover:text-primary"
            onClick={() => onEdit(data)}
          >
            <EditOutlined />
          </span>
          <Popconfirm
            title="Delete the Group"
            description="Are you sure to delete this group?"
            onConfirm={() => onDelete(data?._id)}
            okText="Yes"
            cancelText="No"
            placement="left"
            className="ml-2"
          >
            <DeleteOutlined className="hover:text-red-600" />
          </Popconfirm>
        </span>
      ),
    },
  ];

  return (
    <Container selected={"4"}>
      <div className="flex items-center justify-between">
        <p className="text-2xl font-MontserratBold">Departments</p>
        <ThemeButton
          content={
            <div className="flex items-center justify-center">
              <PlusOutlined />
              <p className="ml-2">Add Department</p>
            </div>
          }
          onClick={() => setIsOpen(true)}
        />
      </div>
      <div className="mt-6 overflow-x-auto">
        <ThemeTable
          columns={columns}
          data={data?.data || []}
          loader={isLoading}
          pagination={{ total, setCurrentPage, setResultPerPage }}
        />
      </div>
      <DepartmentModal
        isOpen={isOpen}
        onClose={onClose}
        form={form}
        onFinish={onFinish}
        isLoading={mode === "create" ? submitting : updating}
        mode={mode}
      />
    </Container>
  );
};

export default DepartmentPage;
