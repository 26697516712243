import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import {
  Container,
  ThemeTable,
  ThemeButton,
  SearchAssetModal,
  CheckAssetModal,
} from "../../components/components";
import {
  FaHandHoldingDollar,
  FaLayerGroup,
  FaLocationDot,
} from "react-icons/fa6";
import { TbBusinessplan } from "react-icons/tb";
import { useGetTransactionQuery } from "../../services/transactionService";
import { NavLink } from "react-router-dom";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Legend,
  Tooltip,
} from "recharts";
import { Alert } from "antd";
import { useGetStatsQuery } from "../../services/statsService";
import {
  useCheckinAssetMutation,
  useCheckoutAssetMutation,
} from "../../services/assetService";
import { getStatusName } from "../../helpers/helpers";

const Dashboard = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isCheckModalOpen, setIsCheckModalOpen] = useState(false);
  const [modalType, setModalType] = useState("checkin");
  const [value, setValue] = useState("");
  const [purpose, setPurpose] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [pieData, setPieData] = useState([]);
  const [colors, setColors] = useState([])
  const { data: stats, isLoading } = useGetStatsQuery();
  const { data: transactions, isLoading: fetchingTransactionLoader } =
    useGetTransactionQuery({ currentPage: 1, resultPerPage: 5 });
  const [checkoutAsset, { isLoading: checkoutLoader }] =
    useCheckoutAssetMutation();
  const [checkinAsset, { isLoading: checkinLoader }] =
    useCheckinAssetMutation();

  useEffect(() => {
    setColors(generateColors())
    getPieData();
  }, [isLoading]);

  useEffect(() => {
    setColors(generateColors(pieData?.length))
  },[pieData])

  const getPieData = () => {
    let list = [];
    stats?.data?.categories?.forEach((category) => {
      list.push({ name: category?.name, value: category?.assets });
    });
    setPieData(list);
  };

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: "10%",
      render: (data, _, index) => (
        <p className="text-center font-medium">{index + 1}</p>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text) => <p className="font-medium text-center">{getStatusName(text)}</p>,
    },
    {
      title: "Purpose",
      dataIndex: "purpose",
      key: "purpose",
      render: (text) => <p className="font-medium text-center">{text}</p>,
    },
    {
      title: "Timestamp",
      dataIndex: "timestamp",
      key: "timestamp",
      render: (text) => (
        <p className="font-medium text-center">
          {dayjs(text).format("YYYY-MMM-DD hh:mm:ss A")}
        </p>
      ),
    },
  ];

  const generateColors = (numColors) => {
    const colors = [];
    const excludedColors = ["#000000", "#FFFFFF"]; // Exclude black and white

    for (let i = 0; i < numColors; i++) {
      let color;
      do {
        color = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
      } while (excludedColors.includes(color));

      colors.push(color);
    }
    return colors;
  };

  const onSubmitCheckModal = async () => {
    let response;

    if (modalType === "checkin") {
      response = await checkinAsset({ id: value, purpose: "" });
    } else {
      response = await checkoutAsset({ id: value, purpose: purpose });
    }

    if (response?.data?.status?.code === 200) {
      setSuccessMessage(
        `Asset ${
          modalType === "checkin" ? "check in" : "check out"
        } successfully`
      );
      setValue("");
      setPurpose("");
    } else {
      setErrorMessage(
        `Asset ${modalType === "checkin" ? "check in" : "check out"} failed`
      );
      setValue("");
      setPurpose("");
    }

    console.log("response", response);
  };

  const onCloseCheckModal = () => {
    setIsCheckModalOpen(false);
    setValue("");
    setPurpose("");
    setSuccessMessage("");
    setErrorMessage("");
  };

  return (
    <Container selected={"1"}>
      <div>
        <div className="flex sm:items-center items-start justify-between   sm:flex-row flex-col">
          <div className="w-full">
            <h1 className="text-2xl font-MontserratBold font-bold">
              Dashboard
            </h1>
            <p className="text-sm font-semibold text-gray-400">
              Dashboard & statistics
            </p>
          </div>
          <div className="flex items-center sm:mt-0 mt-3 sm:justify-end justify-around w-full">
            <ThemeButton
              content={"Search Asset"}
              onClick={() => setIsOpen(true)}
              className={"mx-1  md:h-8 !h-10"}
            />
            <ThemeButton
              content={"Checkout"}
              onClick={() => {
                setModalType("checkout");
                setIsCheckModalOpen(true);
              }}
              className={"mx-1  md:h-8 !h-10"}
            />
            <ThemeButton
              content={"Checkin"}
              onClick={() => {
                setModalType("checkin");
                setIsCheckModalOpen(true);
              }}
              className={"mx-1  md:h-8 !h-10"}
            />
          </div>
        </div>
        <div className="flex md:flex-row flex-col items-center justify-between mt-4">
          <div className="md:p-2 p-0 md:pl-0 md:w-4/12 w-full">
            <div className="flex items-center justify-between my-2 bg-white rounded-md p-3">
              <div className="flex flex-col">
                <p className="md:text-xs text-sm font-MontserratBold text-gray-400 mb-0">
                  Total Assets
                </p>
                <p className="text-base font-MontserratBold">
                  {stats?.data?.total_assets || 0}
                </p>
              </div>

              <div className="bg-[#EB8186] rounded text-white text-2xl w-12 h-12 flex items-center justify-center">
                <TbBusinessplan />
              </div>
            </div>
          </div>

          <div className="md:p-2 p-0 md:w-4/12 w-full">
            <div className="flex items-center justify-between my-2 bg-white rounded-md p-3">
              <div className="flex flex-col">
                <p className="md:text-xs text-sm font-MontserratBold text-gray-400 mb-0">
                  Value of Assets
                </p>
                <p className="text-base font-MontserratBold">
                  ${stats?.data?.total_assets_value || 0}
                </p>
              </div>
              <div className="bg-[#AF9CFF] rounded text-white text-2xl w-12 h-12 flex items-center justify-center">
                <FaHandHoldingDollar />
              </div>
            </div>
          </div>

          <div className="md:p-2 p-0 md:w-4/12 w-full">
            <div className="flex items-center justify-between my-2 bg-white rounded-md p-3">
              <div className="flex flex-col">
                <p className="md:text-xs text-sm font-MontserratBold text-gray-400 mb-0">
                  Total Groups
                </p>
                <p className="text-base font-MontserratBold">
                  {stats?.data?.total_groups || 0}
                </p>
              </div>
              <div className="bg-[#EFA672] rounded text-white text-2xl w-12 h-12 flex items-center justify-center">
                <FaLayerGroup />
              </div>
            </div>
          </div>

          <div className="md:p-2 p-0 md:pr-0 md:w-4/12 w-full">
            <div className="flex items-center justify-between my-2 bg-white rounded-md p-3">
              <div className="flex flex-col">
                <p className="md:text-xs text-sm font-MontserratBold text-gray-400 mb-0">
                  Total Locations
                </p>
                <p className="text-base font-MontserratBold">
                  {stats?.data?.total_locations || 0}
                </p>
              </div>
              <div className="bg-[#7DCAFE] rounded text-white text-2xl w-12 h-12 flex items-center justify-center">
                <FaLocationDot />
              </div>
            </div>
          </div>
        </div>

        <div className="my-4 mt-0 bg-white rounded-xl p-4">
          <p className="text-lg font-MontserratBold mb-2">Groups Stats</p>
          <div className="flex justify-center">
            <ResponsiveContainer width="100%" height={250}>
              <PieChart>
                <Pie
                  data={pieData}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={80}
                  fill="#8884d8"
                  label
                >
                  {pieData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={colors[index]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>

        <div className="mb-4">
          <div className="flex justify-between items-center mb-4">
            <p className="text-lg font-MontserratBold">Transactions</p>
            <NavLink
              to="/transactions"
              className="font-medium hover:text-blue-600"
            >
              View all
            </NavLink>
          </div>
          <ThemeTable
            columns={columns}
            data={transactions?.data?.docs || []}
            loader={fetchingTransactionLoader}
          />
        </div>
      </div>
      <SearchAssetModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
      <CheckAssetModal
        isOpen={isCheckModalOpen}
        onClose={onCloseCheckModal}
        title={modalType === "checkin" ? "Check in Asset" : "Check out Asset"}
        btnTxt={modalType === "checkin" ? "Check in" : "Check out"}
        value={value}
        setValue={(e) => setValue(e)}
        purpose={purpose}
        setPurpose={(e) => setPurpose(e)}
        type={modalType}
        onSubmit={onSubmitCheckModal}
        isLoading={modalType === "checkin" ? checkinLoader : checkoutLoader}
        result={
          (successMessage || errorMessage) && (
            <Alert
              message={successMessage || errorMessage}
              type={successMessage ? "success" : "error"}
            />
          )
        }
      />
    </Container>
  );
};

export default Dashboard;
