import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../constants/constants";
import { getValueFromStorage } from "../helpers/helpers";

export const TransactionApi = createApi({
  reducerPath: "transactions",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      headers.set("Content-type", "application/json");
      headers.set("authorization", `Bearer ${getValueFromStorage("token")}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getTransaction: builder.query({
      query: ({ currentPage = 1, resultPerPage = 10000 }) => ({
        url: `/api/transactions`,
        method: "GET",
        params: {
          page: currentPage,
          limit: resultPerPage,
        },
      }),
    }),
    getTransactionById: builder.query({
      query: (id) => ({
        url: `/api/transactions/${id}`,
        method: "GET",
      }),
    }),
    createTransaction: builder.mutation({
      query: (body) => ({
        url: `/api/transactions`,
        method: "POST",
        body: body,
      }),
    }),
  }),
});

export const {
  useGetTransactionQuery,
  useLazyGetTransactionQuery,
  useGetTransactionByIdQuery,
  useLazyGetTransactionByIdQuery,
  useCreateTransactionMutation,
} = TransactionApi;
