import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Spin, message } from "antd";
import { ThemeInput, ThemeButton } from "../../components/components";
import { AppLogo } from "../../assets/images/images";
import { addKeyToStorage } from "../../helpers/helpers";
import { useSetLoginMutation } from "../../services/auth";
import AppContext from "../../context/AppContext";

const Login = ({ setAuthUser }) => {
  const navigate = useNavigate();
  const [setLogin, { isLoading }] = useSetLoginMutation();
  const { setUserRole } = useContext(AppContext);

  const handleLogin = async (values) => {
    const response = await setLogin({ ...values });
    // console.log("response", response);
    const role =  response?.data?.data?.user?.role
    if (response?.data?.status?.code === 200) {
      message.success("Login successfully");
      addKeyToStorage("token", response?.data?.data?.token);
      setAuthUser(response?.data?.data?.user?._id);
      setUserRole(response?.data?.data?.user?.role);
      navigate("/dashboard");
    } else {
      message.error(
        response?.error?.data?.status?.message || "Something went wrong!"
      );
    }
  };

  return (
    <div className="flex justify-center items-center h-screen w-full bg-white">
      <div className="px-2 md:mt-0 -mt-10">
        <div className="mb-10">
          <div className="flex justify-center">
            <img alt="Count Asset" src={AppLogo} className="w-44" />
          </div>
          <h2 className="mt-6 text-center text-3xl font-bold text-gray-900">
            Login to your account
          </h2>
        </div>
        <div>
          <Form name="normal_login" onFinish={handleLogin}>
            <Form.Item
              className="mb-3"
              name="email"
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                {
                  required: true,
                  message: `Please input your Email!`,
                },
              ]}
            >
              <ThemeInput placeholder={"Email"} inputClassName={"!h-10"} />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your Password!",
                },
              ]}
            >
              <ThemeInput
                placeholder={"Password"}
                password
                inputClassName={"!h-10"}
              />
            </Form.Item>

            <div className="text-sm flex justify-end">
              <a
                href="#"
                className="font-medium text-primary hover:text-underline"
              >
                Forgot your password?
              </a>
            </div>

            <Form.Item>
              <ThemeButton
                type="primary"
                htmlType="submit"
                className={
                  "!py-2 !px-4 !border !border-transparent !text-sm !font-medium !rounded-md !text-white bg-primary w-full mt-6 md:!h-10 !h-11"
                }
                content={
                  <>
                    {isLoading ? (
                      <Spin className="mt-1" />
                    ) : (
                      <div className="flex items-center">
                        <p className="md:text-lg text-base text-center">
                          Log in
                        </p>
                      </div>
                    )}
                  </>
                }
              />
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Login;
