import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../constants/constants";
import { getValueFromStorage } from "../helpers/helpers";

export const LocationApi = createApi({
  reducerPath: "locations",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      headers.set("Content-type", "application/json");
      headers.set("authorization", `Bearer ${getValueFromStorage("token")}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getLocation: builder.query({
      query: ({ currentPage = 1, resultPerPage = 10000 }) => ({
        url: `/api/locations`,
        method: "GET",
        params: {
          current_page: currentPage,
          result_per_page: resultPerPage,
        },
      }),
    }),
    createLocation: builder.mutation({
      query: (body) => ({
        url: `/api/locations`,
        method: "POST",
        body: body,
      }),
    }),
    updateLocation: builder.mutation({
      query: ({ id, body }) => {
        return {
          url: `/api/locations/${id}`,
          method: "PUT",
          body: body,
        };
      },
    }),
    deleteLocation: builder.mutation({
      query: ({ id }) => {
        return {
          url: `api/locations/${id}`,
          method: "DELETE",
        };
      },
    }),
  }),
});

export const {
  useGetLocationQuery,
  useLazyGetLocationQuery,
  useCreateLocationMutation,
  useUpdateLocationMutation,
  useDeleteLocationMutation,
} = LocationApi;
