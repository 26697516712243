import React, { useEffect, useState } from "react";
import { Col, Form, Input, Modal, Row, Select, Spin } from "antd";
import { ThemeButton, ThemeInput } from "../components";
import { useLazyGetDepartmentQuery } from "../../services/departmentService";

const LocationModal = ({
  isOpen,
  onClose,
  onFinish,
  form,
  isLoading,
  mode,
}) => {
  const [departments, setDepartments] = useState([]);
  const [getDepartments] = useLazyGetDepartmentQuery();

  useEffect(() => {
    fetchDepartments();
  }, []);

  const fetchDepartments = async () => {
    let departmentList = [];

    const departmentResponse = await getDepartments({
      currentPage: 1,
      resultPerPage: 10000,
    });
    departmentResponse?.data?.data?.map((department) => {
      departmentList.push({ label: department?.name, value: department?._id });
    });

    setDepartments(departmentList);
  };

  return (
    <Modal open={isOpen} footer={false} onCancel={onClose}>
      <h1 className="text-secondary text-xl font-semibold mb-4">
        {mode === "create" ? "Add Location" : "Update Location"}
      </h1>
      <Form
        name="locationForm"
        form={form}
        autoComplete="true"
        onFinish={onFinish}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 20, lg: 20 }}>
          <Col span={24}>
            <Form.Item
              name="name"
              rules={[
                { required: true, message: "location name is required!" },
              ]}
            >
              <ThemeInput
                label={"Location Name"}
                type={"text"}
                placeholder={"enter location name here..."}
                inputClassName={"h-10"}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <p className={"font-semibold mb-1"}>Address</p>
            <Form.Item name="address">
              <Input.TextArea
                placeholder="write address here..."
                rows={2}
                className="font-medium"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <p className={"font-semibold mb-1"}>Department</p>
            <Form.Item name="department">
              <Select
                mode="multiple"
                allowClear
                style={{
                  width: "100%",
                }}
                placeholder="Please select"
                options={departments}
              />
            </Form.Item>
          </Col>

          <Form.Item className="flex justify-end mt-8 mb-0 w-full">
            <ThemeButton
              htmlType="submit"
              className={"!rounded-none w-32 !h-10"}
              disabled={isLoading}
              content={
                isLoading ? (
                  <Spin className="white-loader" />
                ) : (
                  <p className="!text-xs !font-medium">
                    {mode === "create" ? "ADD LOCATION" : "UPDATE LOCATION"}
                  </p>
                )
              }
            />
          </Form.Item>
        </Row>
      </Form>
    </Modal>
  );
};

export default LocationModal;
