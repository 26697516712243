import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../constants/constants";
import { getValueFromStorage } from "../helpers/helpers";

export const UserApi = createApi({
    reducerPath: "users",
    baseQuery: fetchBaseQuery({
      baseUrl: BASE_URL,
      prepareHeaders: (headers) => {
        headers.set("Content-type", "application/json");
        headers.set("authorization", `Bearer ${getValueFromStorage("token")}`);
        return headers;
      },
    }),
    endpoints: (builder) => ({
      getUser: builder.query({
        query: ({ currentPage = 1, resultPerPage = 10000 }) => ({
          url: `/api/users`,
          method: "GET",
          params: {
            current_page: currentPage,
            result_per_page: resultPerPage,
          },
        }),
      }),
      createUser: builder.mutation({
        query: (body) => ({
          url: `/api/register`,
          method: "POST",
          body: body,
        }),
      }),
      updateUser: builder.mutation({
        query: ({ id, body }) => {
          return {
            url: `/api/user/${id}`,
            method: "PUT",
            body: body,
          };
        },
      }),
      deleteUser: builder.mutation({
        query: ({ id }) => {
          return {
            url: `api/user/${id}`,
            method: "DELETE",
          };
        },
      }),
    }),
  });
  
  export const {
    useGetUserQuery,
    useLazyGetUserQuery,
    useCreateUserMutation,
    useUpdateUserMutation,
    useDeleteUserMutation,
  } = UserApi;
  