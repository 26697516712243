import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../constants/constants";
import { getValueFromStorage } from "../helpers/helpers";

export const GroupApi = createApi({
  reducerPath: "groups",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      headers.set("Content-type", "application/json");
      headers.set("authorization", `Bearer ${getValueFromStorage("token")}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getGroup: builder.query({
      query: ({ currentPage = 1, resultPerPage = 10000 }) => ({
        url: `/api/groups`,
        method: "GET",
        params: {
          current_page: currentPage,
          result_per_page: resultPerPage,
        },
      }),
    }),
    createGroup: builder.mutation({
      query: (body) => ({
        url: `/api/groups`,
        method: "POST",
        body: body,
      }),
    }),
    updateGroup: builder.mutation({
      query: ({ id, body }) => {
        return {
          url: `/api/groups/${id}`,
          method: "PUT",
          body: body,
        };
      },
    }),
    deleteGroup: builder.mutation({
      query: ({ id }) => {
        return {
          url: `api/groups/${id}`,
          method: "DELETE",
        };
      },
    }),
  }),
});

export const {
  useGetGroupQuery,
  useLazyGetGroupQuery,
  useCreateGroupMutation,
  useUpdateGroupMutation,
  useDeleteGroupMutation,
} = GroupApi;
