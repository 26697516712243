import React, { useState, useEffect } from "react";
import {
  GroupModal,
  Container,
  ThemeButton,
  ThemeTable,
} from "../../components/components";
import { PlusOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Popconfirm, message, Form } from "antd";
import {
  useCreateGroupMutation,
  useDeleteGroupMutation,
  useGetGroupQuery,
  useUpdateGroupMutation,
} from "../../services/groupService";

const GroupPage = () => {
  const [form] = Form.useForm();
  const [isOpen, setIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [resultPerPage, setResultPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [mode, setMode] = useState("create");
  const [docId, setDocId] = useState("");
  const { data, isLoading, refetch } = useGetGroupQuery({
    currentPage: currentPage,
    resultPerPage: resultPerPage,
  });
  const [deleteGroup] = useDeleteGroupMutation();

  const [createGroup, { isLoading: submitting }] = useCreateGroupMutation();
  const [updateGroup, { isLoading: updating }] = useUpdateGroupMutation();

  useEffect(() => {
    setTotal(data?.data?.length + 1 || 0);
  }, [isLoading]);

  const onClose = () => {
    form.resetFields();
    setIsOpen(false);
    setMode("create");
  };

  const onFinish = async (values) => {
    let response;
    if (mode === "create") {
      response = await createGroup({ ...values });
    } else {
      response = await updateGroup({ id: docId, body: { ...values } });
    }
    if (!response?.error) {
      message.success(
        `Group ${mode === "create" ? "added" : "updated"} successfully.`
      );
      onClose();
      refetch();
    } else {
      message.error(response?.error?.data?.error || "Please fill form correctly.");
    }
  };

  const onDelete = async (id) => {
    try {
      const res = await deleteGroup({ id });
      if (!res?.data?.error) {
        message.success("Group deleted successfully");
        refetch();
      }
    } catch (err) {
      message.error(err);
    }
  };

  const onEdit = (data) => {
    setMode("edit");
    form.setFieldsValue({...data})
    setDocId(data?._id);
    setIsOpen(true);
  };

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: "10%",
      render: (data, _, index) => (
        <p className="text-center font-medium">{index + 1}</p>
      ),
    },
    {
      title: "Group Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <p className="font-medium text-center">{text}</p>,
    },
    {
      title: "In Action",
      dataIndex: "_id",
      key: "_id",
      width: "10%",
      render: (text, data) => (
        <span className="flex justify-center items-center">
          <span
            className="mx-2 cursor-pointer text-base hover:text-primary"
            onClick={() => onEdit(data)}
          >
            <EditOutlined />
          </span>
          <Popconfirm
            title="Delete the Group"
            description="Are you sure to delete this group?"
            onConfirm={() => onDelete(data?._id)}
            okText="Yes"
            cancelText="No"
            placement="left"
            className="ml-2"
          >
            <DeleteOutlined className="hover:text-red-600" />
          </Popconfirm>
        </span>
      ),
    },
  ];

  return (
    <Container selected={"2"}>
      <div className="flex items-center justify-between">
        <p className="text-2xl font-MontserratBold">Groups</p>
        <ThemeButton
          content={
            <div className="flex items-center justify-center">
              <PlusOutlined />
              <p className="ml-2">Add Group</p>
            </div>
          }
          onClick={() => setIsOpen(true)}
        />
      </div>
      <div className="mt-6 overflow-x-auto">
        <ThemeTable
          columns={columns}
          data={data?.data || []}
          loader={isLoading}
          pagination={{ total, setCurrentPage, setResultPerPage }}
        />
      </div>
      <GroupModal
        isOpen={isOpen}
        onClose={onClose}
        form={form}
        onFinish={onFinish}
        isLoading={mode === "create" ? submitting : updating}
        mode={mode}
      />
    </Container>
  );
};

export default GroupPage;
