import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Modal, Spin } from "antd";
import { ThemeButton, ThemeInput } from "../components";
import { FaArrowRight } from "react-icons/fa";
import { useLazyGetAssetByCodeQuery } from "../../services/assetService";

const SearchAssetModal = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const [searchData, setSearchData] = useState({});
  const [value, setValue] = useState("");
  const [getAssetByCode, { isLoading }] = useLazyGetAssetByCodeQuery();
  useEffect(() => {
    setValue("");
    setSearchData({});
  }, [isOpen]);

  const searchAsset = async () => {
    const response = await getAssetByCode({ code: value });
    console.log("response", response);
    setSearchData(response?.data?.data || {});
  };

  useEffect(() => {
    console.log("searchData", searchData);
  }, [searchData]);

  return (
    <Modal open={isOpen} footer={false} onCancel={onClose}>
      <h1 className="text-secondary text-xl font-semibold mb-4">
        Search Asset
      </h1>
      <ThemeInput
        placeholder={"search asset by scan or asset code..."}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />

      <ThemeButton
        disabled={!value || isLoading}
        content={<>{isLoading ? <Spin /> : "Search Asset"}</>}
        onClick={searchAsset}
        className={"ml-auto mt-4 !h-10 w-32 !rounded-none"}
      />
      <div className="mt-4">
        {Object.keys(searchData).length > 0 ? (
          <div
            className="p-2 border border-primary rounded-md bg-orange-100 flex items-center justify-between"
            onClick={() => navigate(`/asset/detail/${searchData?._id}`)}
          >
            <div className="flex flex-col">
              <p className="text-sm font-semibold">{searchData?.assetName}</p>
              <p className="text-sm">{searchData?.groupDetails?.name}</p>
            </div>
            <div className="flex flex-col">
              <p className="text-sm font-semibold">
                {searchData?.locationDetails?.name}
              </p>
              <p className="text-sm">{searchData?.departmentDetails?.name}</p>
            </div>
            <div className="flex flex-col">
              <p>{searchData?.price}</p>
              <p>{searchData?.status}</p>
            </div>
          </div>
        ) : (
          <div className="p-2 border border-primary rounded-md bg-orange-100 h-10">
            <p className="text-sm font-semibold text-center w-full">
              No Data
            </p>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default SearchAssetModal;
