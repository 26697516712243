import React from "react";
import { Select } from "antd";

const ThemeSelect = ({
  value,
  onChange,
  placeholder,
  options,
  className,
  label,
  labelPrimary,
  textMd,
  ...props
}) => {
  return (
    <div>
      <p
        className={`${labelPrimary && "text-primary"} ${
          textMd ? "font-medium" : "font-semibold"
        } mb-1`}
      >
        {label}
      </p>
      <Select
        {...props}
        style={{ height: 32 }}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className={`${className} selectBox`}
        options={options}
      />
    </div>
  );
};

export default ThemeSelect;
