import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../constants/constants";
import { getValueFromStorage } from "../helpers/helpers";

export const AssetApi = createApi({
  reducerPath: "assets",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      headers.set("Content-type", "application/json");
      headers.set("authorization", `Bearer ${getValueFromStorage("token")}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAsset: builder.mutation({
      query: (body) => ({
        url: `/api/assets/allAssets`,
        method: "POST",
        body: body,
      }),
    }),
    getAssetByCode: builder.query({
      query: ({ code }) => ({
        url: `/api/assetsByCode/${code}`,
        method: "GET",
      }),
    }),
    getAssetById: builder.query({
      query: ({ id }) => ({
        url: `/api/assets/assetsById/${id}`,
        method: "GET",
      }),
    }),
    createAsset: builder.mutation({
      query: (body) => ({
        url: `/api/assets`,
        method: "POST",
        body: body,
      }),
    }),
    updateAsset: builder.mutation({
      query: ({ id, body }) => {
        return {
          url: `/api/assets/${id}`,
          method: "PUT",
          body: body,
        };
      },
    }),
    deleteAsset: builder.mutation({
      query: ({ id }) => {
        return {
          url: `api/assets/${id}`,
          method: "DELETE",
        };
      },
    }),
    checkinAsset: builder.mutation({
      query: ({ id, body }) => ({
        url: `/api/assets/checkin/${id}`,
        method: "POST",
        body: body,
      }),
    }),
    checkoutAsset: builder.mutation({
      query: ({ id, body }) => ({
        url: `/api/assets/checkout/${id}`,
        method: "POST",
        body: body,
      }),
    }),
    writeoffAsset: builder.mutation({
      query: ({ id, body }) => ({
        url: `/api/assets/writeoff/${id}`,
        method: "POST",
        body: body,
      }),
    }),
    transferAsset: builder.mutation({
      query: ({ id, body }) => ({
        url: `/api/assets/transfer/${id}`,
        method: "POST",
        body: body,
      }),
    }),
    maintenanceAsset: builder.mutation({
      query: ({ id, body }) => ({
        url: `/api/assets/maintanance/${id}`,
        method: "POST",
        body: body,
      }),
    }),
    updateCustodian: builder.mutation({
      query: ({ id, body }) => {
        return {
          url: `/api/assets/changecustodian/${id}`,
          method: "PUT",
          body: body,
        };
      },
    }),
  }),
});

export const {
  useGetAssetMutation,
  useGetAssetByCodeQuery,
  useLazyGetAssetByCodeQuery,
  useGetAssetByIdQuery,
  useLazyGetAssetByIdQuery,
  useCreateAssetMutation,
  useUpdateAssetMutation,
  useDeleteAssetMutation,
  useCheckinAssetMutation,
  useCheckoutAssetMutation,
  useWriteoffAssetMutation,
  useTransferAssetMutation,
  useMaintenanceAssetMutation,
  useUpdateCustodianMutation,
} = AssetApi;
