import React from "react";
import { Col, Form, Modal, Row, Spin } from "antd";
import { ThemeButton, ThemeInput } from "../components";

const GroupModal = ({
  isOpen,
  onClose,
  onFinish,
  form,
  isLoading,
  mode,
}) => {
  return (
    <Modal open={isOpen} footer={false} onCancel={onClose}>
      <h1 className="text-secondary text-xl font-semibold mb-4">
        {mode === "create" ? "Add Group" : "Update Group"}
      </h1>
      <Form
        name="GroupForm"
        form={form}
        autoComplete="true"
        onFinish={onFinish}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 20, lg: 20 }}>
          <Col span={24}>
            <Form.Item
              name="name"
              rules={[{ required: true, message: "group name is required!" }]}
            >
              <ThemeInput
                label={"Group Name"}
                type={"text"}
                placeholder={"enter group name here..."}
                inputClassName={"h-10"}
              />
            </Form.Item>
          </Col>

          <Form.Item className="flex justify-end mt-8 mb-0 w-full">
            <ThemeButton
              htmlType="submit"
              className={"!rounded-none w-28 !h-10"}
              disabled={isLoading}
              content={
                isLoading ? (
                  <Spin className="white-loader" />
                ) : (
                  <p className="!text-xs !font-medium">
                    {mode === "create" ? "ADD GROUP" : "UPDATE GROUP"}
                  </p>
                )
              }
            />
          </Form.Item>
        </Row>
      </Form>
    </Modal>
  );
};

export default GroupModal;
