import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row, Spin } from "antd";
import { ThemeButton, ThemeInput, ThemeSelect } from "../components";
import { useLazyGetLocationQuery } from "../../services/locationService";
import { useLazyGetDepartmentQuery } from "../../services/departmentService";

const TransferModal = ({ isOpen, onClose, onFinish, form, isLoading }) => {
  const [locations, setLocations] = useState([]);
  const [departments, setDepartments] = useState([]);

  const [getLocations] = useLazyGetLocationQuery();
  const [getDepartments] = useLazyGetDepartmentQuery();

  useEffect(() => {
    getOptions();
  }, []);

  const getOptions = async () => {
    let locationList = [];
    let departmentList = [];

    const locationResponse = await getLocations({
      currentPage: 1,
      resultPerPage: 10000,
    });

    const departmentResponse = await getDepartments({
      currentPage: 1,
      resultPerPage: 10000,
    });

    locationResponse?.data?.data?.map((location) => {
      locationList.push({ label: location?.name, value: location?._id });
    });

    departmentResponse?.data?.data?.map((department) => {
      departmentList.push({ label: department?.name, value: department?._id });
    });

    setLocations(locationList);
    setDepartments(departmentList);
  };

  return (
    <Modal open={isOpen} footer={false} onCancel={onClose}>
      <h1 className="text-secondary text-xl font-semibold mb-4">
        <h1 className="text-secondary text-xl font-semibold mb-4">
          Transfer Asset
        </h1>
      </h1>
      <Form
        name="transferForm"
        form={form}
        autoComplete="true"
        onFinish={onFinish}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 20, lg: 20 }}>
          <Col span={24}>
            <Form.Item
              name="newLocation"
              rules={[{ required: true, message: "location is required!" }]}
            >
              <ThemeSelect
                label={"Location"}
                placeholder={"select location..."}
                options={locations}
                className={"!h-10 w-full"}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="newDepartment"  rules={[
                { required: true, message: "department is required!" },
              ]}>
              <ThemeSelect
                label={"Department"}
                placeholder={"select department..."}
                options={departments}
                className={"!h-10 w-full"}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="custodian"
              rules={[
                { required: true, message: "custodian name is required!" },
              ]}
            >
              <ThemeInput
                label={"Custodian Name"}
                type={"text"}
                placeholder={"enter custodian name here..."}
                inputClassName={"h-10"}
              />
            </Form.Item>
          </Col>
          <Form.Item className="flex justify-end mt-8 mb-0 w-full">
            <ThemeButton
              htmlType="submit"
              className={"!rounded-none w-24 !h-10 ml-auto"}
              disabled={isLoading}
              content={
                isLoading ? (
                  <Spin className="white-loader" />
                ) : (
                  <p className="!text-xs !font-semibold">
                   Transfer
                  </p>
                )
              }
            />
          </Form.Item>
        </Row>
      </Form>
    </Modal>
  );
};

export default TransferModal;
