import React from "react";
import { Col, Form, Modal, Row, Spin, DatePicker } from "antd";
import { ThemeButton, ThemeInput, Uploader } from "../components";

const CompanyModal = ({
  isOpen,
  onClose,
  onFinish,
  form,
  image,
  onHandleImage,
  errorMessage,
  uploading,
  isLoading,
  mode,
}) => {
  return (
    <Modal
      open={isOpen}
      footer={false}
      onCancel={onClose}
      width={800}
      style={{ top: 0 }}
    >
      <h1 className="text-secondary text-xl font-semibold mb-4">
        {mode === "create" ? "Add Company" : "Update Company"}
      </h1>
      <Form
        name="CompanyForm"
        form={form}
        autoComplete="true"
        onFinish={onFinish}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 20, lg: 20 }}>
          <Col span={24} className="mb-4">
            <Uploader
              label={"Logo"}
              image={image}
              handleImage={(e) => onHandleImage(e)}
              errorMessage={errorMessage}
              uploading={uploading}
            />
          </Col>
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            <Form.Item
              name="name"
              rules={[{ required: true, message: "company name is required!" }]}
            >
              <ThemeInput
                label={"Name"}
                type={"text"}
                placeholder={"enter company name here..."}
                inputClassName={"h-10"}
              />
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            <Form.Item
              name="email"
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                {
                  required: true,
                  message: `email is required!`,
                },
              ]}
            >
              <ThemeInput
                label={"Email"}
                type={"email"}
                disabled={mode === "edit"}
                placeholder={"enter company email here..."}
                inputClassName={"h-10"}
              />
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: `password is required!`,
                },
              ]}
            >
              <ThemeInput
                label={"Password"}
                password
                placeholder={"enter password here..."}
                inputClassName={"h-10"}
              />
            </Form.Item>
          </Col>
          <Col xl={6} lg={6} md={6} sm={12} xs={12}>
            <p className={"font-semibold mb-1"}>Start Date</p>
            <Form.Item name="startDate">
              <DatePicker className="w-full !h-11" />
            </Form.Item>
          </Col>
          <Col xl={6} lg={6} md={6} sm={12} xs={12}>
            <p className={"font-semibold mb-1"}>End Date</p>
            <Form.Item name="endDate">
              <DatePicker className="w-full !h-11" />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item className="flex justify-end mt-8 mb-0 w-full">
          <ThemeButton
            htmlType="submit"
            className={"!rounded-none w-32 !h-10"}
            disabled={isLoading}
            content={
              isLoading ? (
                <Spin className="white-loader" />
              ) : (
                <p className="!text-xs !font-medium">
                  {mode === "create" ? "ADD COMPANY" : "UPDATE COMPANY"}
                </p>
              )
            }
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CompanyModal;
