import React from "react";
import { Layout, Menu } from "antd";
import { ThemeButton } from "../components";
import { AppLogo } from "../../assets/images/images";

const { Sider } = Layout;

const Sidebar = ({ selected, menuItems, onHandleRoute, onLogout }) => {
  return (
    <Sider
      trigger={null}
      className="bg-white rounded-tr-md rounded-br-md h-screen fixed z-50"
    >
      <img alt="Count Asset" src={AppLogo} className="w-36 mx-auto" />

      <Menu
        mode="inline"
        defaultSelectedKeys={[selected]}
        className="bg-white mt-2"
        items={menuItems}
        onSelect={(items) => onHandleRoute(items?.key)}
      />
      <div className="w-full flex justify-center absolute bottom-5">
        <ThemeButton
          className={"w-10/12 !h-10"}
          onClick={onLogout}
          content={<p className="text-lg font-MontserratBold">Logout</p>}
        />
      </div>
    </Sider>
  );
};

export default Sidebar;
