import { useContext, useEffect, useLayoutEffect, useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";
import {
  Login,
  Dashboard,
  AssetRegister,
  AssetForm,
  LocationPage,
  GroupPage,
  DepartmentPage,
  AssetDetail,
  Transactions,
  CategoryPage,
  CompanyPage,
  Users,
} from "../pages/pages";
import { Result } from "antd";
import { getUserInfo } from "../helpers/helpers";
// import AppContext from "../context/AppContext";

function ScrollToTop() {
  const { pathname } = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

getUserInfo();

function AppRouter() {
  const [user, setUser] = useState(getUserInfo()?.user);
  // const { userRole } = useContext(AppContext);
  const { role, permissions } = getUserInfo()?.user || {};
  // const [allowedRoutes, setAllowedRoutes] = useState([]);

  // useEffect(() => {
  //   console.log("userRole", userRole);
  // }, [userRole]);

  useEffect(() => {
    // generateRoutes(role, permissions);
    setAuthUser();
  }, [user]);

  // useEffect(() => {
  //   console.log("allowed Routes", allowedRoutes);
  // }, [allowedRoutes]);

  // const generateRoutes = (role, permissions) => {
  //   // const commonRoutes = [

  //   //   // Add other common routes here
  //   // ];

  //   if (role === "super_admin") {
  //     setAllowedRoutes([...commonRoutes]);
  //   } else {
  //     const filteredRoutes = commonRoutes.filter((route) => {
  //       const routePath = route.props.path;

  //       if (role === "company_admin" && routePath === "/company") {
  //         return false;
  //       }

  //       if (
  //         (role === "location_admin" || role === "tracking_admin") &&
  //         permissions[routePath]
  //       ) {
  //         return true;
  //       }
  //       return true
  //     });
  //     setAllowedRoutes(filteredRoutes);
  //   }
  // };

  const ProtectedRoute = ({ user, children }) => {
    if (!user) {
      return <Navigate to="/" />;
    }
    return children;
  };

  const setAuthUser = (auth) => {
    const id = getUserInfo()?.user?.id;
    setUser(auth || id);
  };

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route exact path="/" element={<Login setAuthUser={setAuthUser} />} />
        <Route
          exact
          key="dashboard"
          path="/dashboard"
          element={
            <ProtectedRoute user={user}>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          key={"transactions"}
          path="/transactions"
          element={
            <ProtectedRoute user={user}>
              <Transactions />
            </ProtectedRoute>
          }
        />
        {role === "company_admin" && (
          <Route
            exact
            key={"users"}
            path="/users"
            element={
              <ProtectedRoute user={user}>
                <Users />
              </ProtectedRoute>
            }
          />
        )}
        {role === "company_admin" && (
          <Route
            exact
            key={"userId"}
            path="/user/:id"
            element={
              <ProtectedRoute user={user}>
                <Users />
              </ProtectedRoute>
            }
          />
        )}
        {role === "super_admin" && (
          <Route
            exact
            key={"company"}
            path="/company"
            element={
              <ProtectedRoute user={user}>
                <CompanyPage />
              </ProtectedRoute>
            }
          />
        )}
        <Route
          exact
          key={"group"}
          path="/group"
          element={
            <ProtectedRoute user={user}>
              <GroupPage />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          key={"category"}
          path="/category"
          element={
            <ProtectedRoute user={user}>
              <CategoryPage />
            </ProtectedRoute>
          }
        />

        <Route
          exact
          key={"location"}
          path="/location"
          element={
            <ProtectedRoute user={user}>
              <LocationPage />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          key={"department"}
          path="/department"
          element={
            <ProtectedRoute user={user}>
              <DepartmentPage />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          key={"asset"}
          path="/asset"
          element={
            <ProtectedRoute user={user}>
              <AssetRegister />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          key={"assetAdd"}
          path="/asset/add"
          element={
            <ProtectedRoute user={user}>
              <AssetForm />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          key={"assetId"}
          path="/asset/:id"
          element={
            <ProtectedRoute user={user}>
              <AssetForm />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          key={"assetDetail"}
          path="/asset/detail/:id"
          element={
            <ProtectedRoute user={user}>
              <AssetDetail />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          key={"404"}
          path="*"
          element={
            <Result
              status="404"
              title="404"
              subTitle="Sorry, the page you visited does not exist."
            />
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default AppRouter;
