import { Modal, Form, Row, Col, Input, Spin,DatePicker } from "antd";
import React from "react";
import { ThemeInput, ThemeButton } from "../components";

const MaintenanceModal = ({ isOpen, onClose, isLoading, onFinish, formRef }) => {
  return (
    <Modal open={isOpen} footer={false} onCancel={onClose}>
      <h1 className="text-secondary text-xl font-semibold mb-4">Maintenance</h1>
      <Form
        name="maintenanceForm"
        autoComplete="true"
        onFinish={onFinish}
        ref={formRef}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 20, lg: 20 }}>
          <Col span={24}>
            <p className="font-semibold mb-1">Purpose</p>
            <Form.Item
              name="purpose"
              rules={[{ required: true, message: "purpose is required!" }]}
            >
              <Input.TextArea
                placeholder="write purpose here..."
                rows={5}
                className="font-medium"
              />
            </Form.Item>
          </Col>
            <Col span={24}>
              <Form.Item
                name="maintenanceCost"
                rules={[{ required: true, message: "price is required!" }]}
              >
              <ThemeInput
                label={"Price"}
                placeholder={0}
                type={"number"}
                inputClassName={"h-10"}
              />
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                  <p className={"font-semibold mb-1"}>Maintenence Date</p>
                  <Form.Item
                    name="maintenanceDate"
                  >
                    <DatePicker className="w-full !h-11" />
                  </Form.Item>
                </Col>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                  <p className={"font-semibold mb-1"}>Due Maintenence Date</p>
                  <Form.Item
                    name="dueMaintenanceDate"
                  >
                    <DatePicker className="w-full !h-11" />
                  </Form.Item>
                </Col>
          <Form.Item className="flex justify-end mt-8 mb-0 w-full">
            <ThemeButton
              htmlType="submit"
              className={"!rounded-none w-32 !h-10"}
              disabled={isLoading}
              content={
                isLoading ? (
                  <Spin className="white-loader" />
                ) : (
                  <p className="!text-xs !font-medium">MAINTENANCE</p>
                )
              }
            />
          </Form.Item>
        </Row>
      </Form>
    </Modal>
  );
};

export default MaintenanceModal;
