// Layout.js
import React, { useContext, useEffect, useState } from "react";
import Navbar from "./navbar";
import Sidebar from "./sidebar";
import MenuDrawer from "./menuDrawer";
import { MdDashboard, MdCategory } from "react-icons/md";
import {
  FaLayerGroup,
  FaLocationDot,
  FaHouse,
  FaBuilding,
} from "react-icons/fa6";
import { TbBusinessplan } from "react-icons/tb";
import "./index.css";
import { useNavigate } from "react-router-dom";
import { deleteKeyFromStorage, getUserInfo } from "../../helpers/helpers";
// import AppContext from "antd/es/app/context";

const Container = ({ selected, children }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  // const { userRole } = useContext(AppContext);
  const { role, permissions } = getUserInfo()?.user || {};

  useEffect(() => {
    getMenuItems();
  }, [role]);

  const onHandleRoute = (key) => {
    key == 1 && navigate("/dashboard");
    key == 2 && navigate("/group");
    key == 3 && navigate("/location");
    key == 4 && navigate("/department");
    key == 5 && navigate("/asset");
    key == 6 && navigate("/category");
    key == 7 && navigate("/company");
    key == 8 && navigate("/users");
  };

  const onLogout = () => {
    deleteKeyFromStorage("token");
    navigate("/");
  };

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const superItems = [
    getItem(
      "Dashboard",
      "1",
      <span className="text-base">
        <MdDashboard />
      </span>
    ),
    getItem(
      "Group",
      "2",
      <span className="text-base">
        <FaLayerGroup />
      </span>
    ),
    getItem(
      "Company",
      "7",
      <span className="text-base">
        <FaBuilding />
      </span>
    ),
    getItem(
      "Category",
      "6",
      <span className="text-base">
        <MdCategory />
      </span>
    ),
    getItem(
      "Department",
      "4",
      <span className="text-base">
        <FaHouse />
      </span>
    ),
    getItem(
      "Location",
      "3",
      <span className="text-base">
        <FaLocationDot />
      </span>
    ),
    getItem(
      "Asset",
      "5",
      <span className="text-base">
        <TbBusinessplan />
      </span>
    ),
  ];
  const companyItems = [
    getItem(
      "Dashboard",
      "1",
      <span className="text-base">
        <MdDashboard />
      </span>
    ),
    getItem(
      "Users",
      "8",
      <span className="text-base">
        <MdDashboard />
      </span>
    ),
    getItem(
      "Group",
      "2",
      <span className="text-base">
        <FaLayerGroup />
      </span>
    ),
    getItem(
      "Category",
      "6",
      <span className="text-base">
        <MdCategory />
      </span>
    ),
    getItem(
      "Department",
      "4",
      <span className="text-base">
        <FaHouse />
      </span>
    ),
    getItem(
      "Location",
      "3",
      <span className="text-base">
        <FaLocationDot />
      </span>
    ),
    getItem(
      "Asset",
      "5",
      <span className="text-base">
        <TbBusinessplan />
      </span>
    ),
  ];
  const userItems = [
    getItem(
      "Dashboard",
      "1",
      <span className="text-base">
        <MdDashboard />
      </span>
    ),
    getItem(
      "Category",
      "6",
      <span className="text-base">
        <MdCategory />
      </span>
    ),
    getItem(
      "Group",
      "2",
      <span className="text-base">
        <FaLayerGroup />
      </span>
    ),
    getItem(
      "Department",
      "4",
      <span className="text-base">
        <FaHouse />
      </span>
    ),
    getItem(
      "Asset",
      "5",
      <span className="text-base">
        <TbBusinessplan />
      </span>
    ),
  ];

  const getMenuItems = () => {
    switch (role) {
      case "super_admin":
        return superItems;
      case "company_admin":
        return companyItems;
      case "location_admin":
        return userItems;
      case "tracking_admin":
        return userItems;
      default:
        return userItems;
    }
  };

  return (
    <div className="flex">
      <div className="md:flex hidden">
        <Sidebar
          selected={selected}
          menuItems={getMenuItems()}
          onHandleRoute={onHandleRoute}
          onLogout={onLogout}
        />
      </div>
      <Navbar onClick={() => setIsOpen(true)} />
      <div
        className={`md:pl-[13rem] pl-2 md:pr-6 pr-2 md:pt-6 pt-32 overflow-x-hidden w-full h-full`}
      >
        {children}
      </div>
      <div className="md:hidden flex">
        <MenuDrawer
          open={isOpen}
          onClose={() => setIsOpen(false)}
          selected={selected}
          menuItems={getMenuItems()}
          onHandleRoute={onHandleRoute}
          onLogout={onLogout}
        />
      </div>
    </div>
  );
};

export default Container;
