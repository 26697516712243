import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  ThemeButton,
  ThemeInput,
  ThemeSelect,
  Uploader,
} from "../../components/components";
import {
  Form,
  Row,
  Col,
  Skeleton,
  Spin,
  Input,
  DatePicker,
  message,
} from "antd";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";
import categoryOptions from "../../constants/data/options.json";
import { useLazyGetLocationQuery } from "../../services/locationService";
import { useLazyGetDepartmentQuery } from "../../services/departmentService";
import { useLazyGetGroupQuery } from "../../services/groupService";
import {
  useCreateAssetMutation,
  useLazyGetAssetByIdQuery,
  useUpdateAssetMutation,
} from "../../services/assetService";
import dayjs from "dayjs";
import { uploadImage } from "../../helpers/helpers";

const AssetForm = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  const [mode, setMode] = useState("create");
  const [image, setImage] = useState("");
  const [imageFile, setImageFile] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [uploading, setUploading] = useState(false);
  const [groups, setGroups] = useState([]);
  const [locations, setLocations] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [getAssetById, { isLoading }] = useLazyGetAssetByIdQuery();
  const [getLocations] = useLazyGetLocationQuery();
  const [getDepartments] = useLazyGetDepartmentQuery();
  const [getGroups] = useLazyGetGroupQuery();
  const [addAsset] = useCreateAssetMutation();
  const [updateAsset] = useUpdateAssetMutation();

  const allowedFormats = ["image/jpeg", "image/png", "image/jpg"];

  useEffect(() => {
    if (id) {
      fetchAssetData();
    }
  }, []);

  useEffect(() => {
    getSelectOptions();
  }, []);

  const fetchAssetData = async () => {
    const response = await getAssetById({ id });
    setMode("edit");
    console.log("response", response);
    form.setFieldsValue({
      ...response?.data?.data,
      expiryDate: response?.data?.data?.expiryDate
        ? dayjs(response?.data?.data?.expiryDate)
        : "",
    });
    setImage(response?.data?.data?.picture);
  };

  const getSelectOptions = async () => {
    let groupList = [];
    let locationList = [];
    let departmentList = [];

    const groupResponse = await getGroups({
      currentPage: 1,
      resultPerPage: 10000,
    });
    const locationResponse = await getLocations({
      currentPage: 1,
      resultPerPage: 10000,
    });
    const departmentResponse = await getDepartments({
      currentPage: 1,
      resultPerPage: 10000,
    });

    groupResponse?.data?.data?.map((group) => {
      groupList.push({ label: group?.name, value: group?._id });
    });

    locationResponse?.data?.data?.map((location) => {
      locationList.push({ label: location?.name, value: location?._id });
    });

    departmentResponse?.data?.data?.map((department) => {
      departmentList.push({ label: department?.name, value: department?._id });
    });

    setGroups(groupList);
    setLocations(locationList);
    setDepartments(departmentList);
  };

  const onBack = () => {
    form.resetFields();
    navigate("/asset");
  };

  const onHandleImage = (e) => {
    const selectedImage = e.target.files[0];
    if (selectedImage) {
      setUploading(true);
      if (allowedFormats.includes(selectedImage.type)) {
        setImage(URL.createObjectURL(selectedImage));
        setImageFile(selectedImage);
        setErrorMessage("");
        setUploading(false);
      } else {
        setImage("");
        setImageFile("");
        setErrorMessage(
          "Invalid file format. Please select a JPEG, PNG or JPG image."
        );
        setUploading(false);
      }
    }
  };

  const onFinish = async (values) => {
    setSubmitting(true);
    const url = await uploadImage(imageFile);
    let response;

    let body = {
      ...values,
      expiryDate: values?.expiryDate
        ? dayjs(values?.expiryDate).toISOString()
        : "",
      picture: url,
    };
console.log('body',body);
    if (mode === "create") {
      response = await addAsset(body);
    } else {
      response = await updateAsset({ id, body });
    }

    console.log("response", response);
    if (!response?.error) {
      setSubmitting(false);
      message.success(
        `Asset ${mode === "create" ? "added" : "update"} successfully`
      );
      form.resetFields();
      navigate("/asset");
    } else {
      setSubmitting(false);
      message.error(response?.error?.data?.error || "Something went wrong!");
    }
  };

  return (
    <Container selected={"5"}>
      <div className="bg-white rounded-md w-full h-full p-6">
        {isLoading ? (
          <Skeleton />
        ) : (
          <div>
            <div className="flex items-center mb-6">
              <span className="text-2xl cursor-pointer mr-4" onClick={onBack}>
                <FaArrowLeftLong />
              </span>
              <h1 className="text-secondary text-2xl font-MontserratBold">Asset</h1>
            </div>

            <Form name="assetForm" form={form} onFinish={onFinish}>
              <Row gutter={{ xs: 8, sm: 16, md: 20, lg: 20 }}>
                <Col span={24} className="mb-4">
                  <Uploader
                    label={"Picture"}
                    image={image}
                    handleImage={(e) => onHandleImage(e)}
                    errorMessage={errorMessage}
                    uploading={uploading}
                  />
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="assetName"
                    rules={[{ required: true, message: "Name is required!" }]}
                  >
                    {/* <Select
                      showSearch
                      // value={selectedValue}
                      placeholder="Select or type to add"
                      optionFilterProp="children"
                      options={[
                        { label: "test", value: "test" },
                        { label: "test1", value: "test1" },
                        { label: "test2", value: "test2" },
                      ]}
                      // onSearch={handleSearch}
                      // onSelect={handleSelect}
                      // filterOption={(input, option) =>
                      //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      // }
                    /> */}
                    <ThemeInput
                      label={"Name"}
                      type={"text"}
                      placeholder={"enter asset name here..."}
                      inputClassName={"h-10"}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <p className={"font-semibold mb-1"}>Description</p>
                  <Form.Item name="assetDescription">
                    <Input.TextArea
                      placeholder="write asset description here..."
                      rows={5}
                      className="font-medium pt-2"
                    />
                  </Form.Item>
                </Col>
                <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                  <Form.Item
                    name="group"
                    rules={[{ required: true, message: "Group is required!" }]}
                  >
                    <ThemeSelect
                      label={"Group"}
                      placeholder={"select group..."}
                      options={groups}
                      className={"!h-11"}
                    />
                  </Form.Item>
                </Col>
                <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                  <Form.Item
                    name="category"
                    rules={[
                      { required: true, message: "Category is required!" },
                    ]}
                  >
                    <ThemeSelect
                      label={"Category"}
                      placeholder={"select category..."}
                      options={categoryOptions}
                      className={"!h-11"}
                    />
                  </Form.Item>
                </Col>
                <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                  <Form.Item
                    name="type"
                    rules={[{ required: true, message: "Type is required!" }]}
                  >
                    <ThemeInput
                      label={"Type"}
                      placeholder={"enter type here..."}
                    />
                  </Form.Item>
                </Col>
                <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                  <Form.Item
                    name="brand"
                    rules={[{ required: true, message: "Brand is required!" }]}
                  >
                    <ThemeInput
                      label={"Brand"}
                      placeholder={"enter brand here..."}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="vendor"
                    rules={[{ required: true, message: "Vendor is required!" }]}
                  >
                    <ThemeInput
                      label={"Vendor"}
                      placeholder={"enter vendor name here..."}
                    />
                  </Form.Item>
                </Col>
                {/* <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                  <Form.Item
                    name="custodian"
                    rules={[
                      { required: true, message: "Custodian is required!" },
                    ]}
                  >
                    <ThemeInput
                      label={"Custodian"}
                      placeholder={"enter Custodian name here..."}
                    />
                  </Form.Item>
                </Col> */}
                <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                  <Form.Item
                    name="location"
                    rules={[
                      { required: true, message: "Location is required!" },
                    ]}
                  >
                    <ThemeSelect
                      label={"Location"}
                      placeholder={"select location"}
                      options={locations}
                      className={"!h-11"}
                    />
                  </Form.Item>
                </Col>
                <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                  <Form.Item
                    name="department"
                    rules={[
                      { required: true, message: "Department is required!" },
                    ]}
                  >
                    <ThemeSelect
                      label={"Department"}
                      placeholder={"select department"}
                      options={departments}
                      className={"!h-11"}
                    />
                  </Form.Item>
                </Col>
                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Form.Item
                    name="price"
                    rules={[{ required: true, message: "Price is required!" }]}
                  >
                    <ThemeInput
                      type={"number"}
                      label={"Price"}
                      placeholder={"1"}
                    />
                  </Form.Item>
                </Col>
                {mode === "create" && (
                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Form.Item
                    name="qty"
                    rules={[{ required: true, message: "QTY is required!" }]}
                  >
                    <ThemeInput label={"QTY"} placeholder={"1"} />
                  </Form.Item>
                </Col>
                )}
                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                  <p className={"font-semibold mb-1"}>Expiry Date</p>
                  <Form.Item
                    name="expiryDate"
                  >
                    <DatePicker className="w-full !h-11" />
                  </Form.Item>
                </Col>
                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Form.Item
                    name="usefulLife"
                  >
                    <ThemeInput
                      label={"Useful Life"}
                      placeholder={"1 year"}
                      type={"number"}
                      min={1}
                    />
                  </Form.Item>
                </Col>
                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Form.Item
                    name="salvageValue"
                    rules={[
                      { required: true, message: "Salvage value is required!" },
                    ]}
                  >
                    <ThemeInput
                      type={"number"}
                      label={"Salvage Value"}
                      placeholder={"0"}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item className="flex justify-end mt-8 mb-0">
                <ThemeButton
                  htmlType="submit"
                  className={"!rounded-none w-36 !h-10"}
                  disabled={submitting}
                  content={
                    submitting ? (
                      <Spin className="white-loader" />
                    ) : (
                      <p className="!text-xs !font-semibold">
                        {mode === "create" ? "ADD" : "UPDATE"} ASSET
                      </p>
                    )
                  }
                />
              </Form.Item>
            </Form>
          </div>
        )}
      </div>
    </Container>
  );
};

export default AssetForm;
