import { Modal, Input } from "antd";
import React from "react";
import { ThemeInput } from "../components";
import { InfoCircleOutlined } from "@ant-design/icons";

const CheckAssetModal = ({
  isOpen,
  onClose,
  title,
  value,
  setValue,
  purpose,
  setPurpose,
  onSubmit,
  isLoading,
  result,
  type,
}) => {
  return (
    <Modal open={isOpen} footer={false} onCancel={onClose}>
      <h1 className="text-secondary text-xl font-semibold mb-2">{title}</h1>
      <p className="text-blue-600 font-semibold mb-4 text-xs">
        <span className={"mr-1"}>
          <InfoCircleOutlined />
        </span>
        Press enter for submission
      </p>
      <ThemeInput
        placeholder={"scan or enter asset code..."}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onSubmit();
          }
        }}
        disabled={isLoading}
      />
      {type === "checkout" && (
        <Input.TextArea
          placeholder="write purpose here..."
          rows={5}
          value={purpose}
          onChange={(e) => setPurpose(e.target.value)}
          className="font-medium pt-2 mt-4"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onSubmit();
            }
          }}
          disabled={isLoading}
        />
      )}
      <div className="mt-4">{result}</div>
    </Modal>
  );
};

export default CheckAssetModal;
